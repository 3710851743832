<script lang="ts" setup>
const alertMap = reactive(new Map<number, { type: "success" | "error"; title?: string; message: string }>());

function addToast(toast: { type: "success" | "error"; message: string; title?: string }) {
  const key = Date.now();
  alertMap.set(key, toast);
  setTimeout(() => alertMap.delete(key), 7000); // auto clear toast with delay
}

const addErrorToast = (message: string, title?: string) => addToast({ type: "error", title, message });
const addSuccessToast = (message: string, title?: string) => addToast({ type: "success", title, message });

const colors = {
  error: "red",
  success: "green",
} as const;

provideToast({ error: addErrorToast, success: addSuccessToast });
</script>

<template>
  <div v-if="alertMap.size !== 0" class="z-[99] w-full md:w-[560px] pxl-toast pxl-toast-top pxl-toast-center">
    <UAlert
      v-for="[key, alert] in alertMap"
      :key="alert.message"
      :color="colors[alert.type]"
      :title="alert.title"
      :description="alert.message"
      class="mx-auto"
      closable
      @close="alertMap.delete(key)"
    />
  </div>
</template>

<style lang="scss">
/* Toast */
.pxl-toast {
  @apply fixed flex flex-col min-w-fit gap-2 p-4;
}
.pxl-toast > * {
  animation: toast-pop 0.25s ease-out;
}
:where(.pxl-toast) {
  @apply right-0 left-auto top-auto bottom-0 translate-x-0 translate-y-0;
}
.pxl-toast:where(.pxl-toast-start) {
  @apply right-auto left-0 translate-x-0;
}
.pxl-toast:where(.pxl-toast-center) {
  @apply right-1/2 left-1/2 -translate-x-1/2;
}
.pxl-toast:where(.pxl-toast-end) {
  @apply right-0 left-auto translate-x-0;
}
.pxl-toast:where(.pxl-toast-bottom) {
  @apply top-auto bottom-0 translate-y-0;
}
.pxl-toast:where(.pxl-toast-middle) {
  @apply top-1/2 bottom-auto -translate-y-1/2;
}
.pxl-toast:where(.pxl-toast-top) {
  @apply top-0 bottom-auto translate-y-0;
}

@keyframes toast-pop {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
