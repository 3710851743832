import type { User } from "firebase/auth";
import { StorageSerializers, useStorage } from "@vueuse/core";
import type { IResponseAtlitUser, IResponsePaymentUserProfile, IUser } from "~/types";
import formatUser from "~/helpers/format-user";

type IUserProfile = IResponsePaymentUserProfile["customer"] & {
  hasOwnTeam: boolean;
};

export function useUser() {
  return useStorage<IUser | null>("user", null, undefined, { serializer: StorageSerializers.object });
}

export function useUserToken() {
  return useStorage<string | null>("token", null, undefined, { serializer: StorageSerializers.string });
}

export function useUserProfile() {
  return useStorage<IUserProfile | null>("user-profile", null, undefined, { serializer: StorageSerializers.object });
}

export function getAtlitUser(): Promise<{ id: string; customData: IResponseAtlitUser["attributes"]["custom"] }> {
  return useAPI<{ data: IResponseAtlitUser }>("/api/v1/users/me").then((res) => ({
    id: res.data.id,
    customData: res.data.attributes.custom,
  }));
}

export function patchAtlitUser(body: { custom: IResponseAtlitUser["attributes"]["custom"] }): Promise<any> {
  return useAPI<{ data: IResponseAtlitUser }>("/api/v1/users/me", {
    method: "PATCH",
    body,
  });
}

export function getUserProfile(): Promise<IUserProfile | null> {
  return useAPI<IResponsePaymentUserProfile>("/api/v1/payment/users/profile").then((response) =>
    response.customer ? { ...response.customer, hasOwnTeam: Boolean(response.activeTeam) } : null,
  );
}

export async function updateUser(currentUser: User): Promise<void> {
  const user = useUser();
  const token = useUserToken();

  const idTokenResult = await currentUser.getIdTokenResult().catch((error) => {
    console.error(error);
    return null;
  });

  if (!idTokenResult) {
    return;
  }

  user.value = formatUser(currentUser, idTokenResult);
  token.value = idTokenResult.token;
}

export function updateUserPhone(phoneNumber: string) {
  return useAPI("/api/v1/payment/users/customer", {
    method: "PUT",
    body: { phone: phoneNumber },
  });
}

export function clearUser(): void {
  const user = useUser();
  const token = useUserToken();
  const segment = useSegment();
  const userProfile = useUserProfile();

  clearMemoizedCaches();
  clearPersistedQueries();

  segment.reset();
  user.value = null;
  token.value = null;
  userProfile.value = null;

  // Reset Zoho session
  // @ts-ignore
  window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.reset && window.$zoho.salesiq.reset();
}

export function logoutUser(): void {
  const { $firebaseAuth } = useNuxtApp();

  clearUser();

  $firebaseAuth.signOut();
}

export function preloadUserData() {
  const user = useUser();
  const userProfile = useUserProfile();

  const p0 = getAtlitUser().then((atlitUser) => {
    if (user.value) {
      user.value.atlitId = atlitUser.id;
      user.value.customData = atlitUser.customData;
    }
  });
  const p1 = getUserProfile().then((profile) => {
    userProfile.value = profile;
  });
  const p2 = getCachedLeaguesList();
  const p3 = getCachedOrganizationsList();
  const p4 = getCachedUserTeamsList({
    limit: 20,
    role: "admin,owner,viewer",
    relationships: "memberships,organizations",
    offset: 0,
  });

  return Promise.all([p0, p1, p2, p3, p4]).catch((err) => console.error(err));
}

/**
 * Removes any cache data stored in maps of `useMemoize` based functions
 */
function clearMemoizedCaches(): void {
  getCachedUserClips.clear();
  getCachedEventGameStatsById.clear();
  getCachedEventAthletesStatsById.clear();
  getCachedTeamStatsTypeOptionsByEventId.clear();
  getCachedAthleteStatsTypeOptionsByEventId.clear();
  getCachedEventsList.clear();
  getCachedEventById.clear();
  getCachedFavoriteVideos.clear();
  getCachedLeaguesList.clear();
  getCachedLeagueById.clear();
  getCachedOrganizationsList.clear();
  getCachedUserPlaylists.clear();
  getCachedTeamsList.clear();
  getCachedUserTeamsList.clear();
  getCachedUserTeamById.clear();
  getCachedAthletesList.clear();
  getCachedAthleteById.clear();
}

/**
 * Removes any persisted queries from the session storage (see use-route-query-session composable)
 */
function clearPersistedQueries() {
  Object.keys(window.sessionStorage)
    .filter((key) => key.indexOf("query-") === 0)
    .forEach((key) => {
      window.sessionStorage.removeItem(key);
    });
}
