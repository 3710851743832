import type { IPixellotPlayerSourceType } from "~/modules/pixellot-sdk";
import type {
  IBasicVideo,
  IEvent,
  IEventGameType,
  IOrganization,
  ITag,
  ITeam,
  IPlaylist,
  IPlaylistItem,
  ITeamUserRole,
  TOrganizationType,
} from "~/types";

interface ITrackEvent {
  event_id: string;
  event_name: string;
  sport_type: string;
  event_status: string;
  event_type: string;
  game_type?: IEventGameType;
  vidswap_id?: number;
}

interface ITrackVideo {
  video_id: string;
  event_id: string;
  video_name: string;
  video_category: string;
}

interface ITrackPlaylist {
  playlist_id: string;
  playlist_name: string;
  playlist_type: string;
  number_of_tags: number;
}

interface IAskToJoinData {
  team_id: string;
  team_name: string;
  team_location?: string | null;
  team_list: "suggested by area" | "search";
  team_account_package: "club" | "team";
  number_of_found_teams: number;
}

interface ITrackTeam {
  id: string;
  color: string;
  name: string;
  sport: string;
  shortName: string;
}

interface ITrackOrganization {
  id: string;
  name: string;
}

interface ITrackPurchaseBreakdowns {
  source_of_intent_initiated: string;
  credit_type: string;
  credit_package_count: number;
  credit_associated_with_team: string;
  credit_associated_with_sport_type: string;
}

interface ITrackAssignBreakdowns {
  credit_type: string;
  team_name?: string;
  team_id?: string;
  sport_type?: string;
  credit_count: number;
}

interface ITrackSendForBreakdown {
  event_id: string;
  credit_type: string;
  team_name: string;
  team_id: string;
  sport_type: string;
  game_type?: string;
  be_response_errors?: string;
}

interface ITrackVideoFilters {
  team_id?: string | string[];
  sportType?: string;
  searchValue?: string;
  event_date?: string[];
  organizationType?: TOrganizationType;
}

interface ITrackSystemPlaylist {
  system_playlist_id: string;
  system_playlist_name: string;
}

interface ITrackPlaylistTags {
  tag_ids: (number | string)[];
  playlist_id: string | undefined | null;
  event_id: string | undefined;
  source: string | undefined;
  playlist_selected_count: number;
}

interface ITrackClipFromTags {
  clip_name: string;
  number_of_tags: number;
  music: string | null;
  audio: boolean;
  graphics: boolean;
}

interface ITrackTag extends ITrackEvent {
  tag_type: ITag["type"] | IPlaylistItem["type"];
  tag_time: ITag["startTime"];
  tag_end_time: ITag["endTime"];
  tag_title: ITag["title"];
  tag_player_number?: ITag["playerNumber"];
}

export interface ISegmentTracks {
  "Click On Hide Suggested Moment": ITrackEvent & { suggestedFilter: boolean };
  "Select All Filter Tags": ITrackEvent & { SelectAllTypeTags: string; SelectAllPlayerTags: string };
  "Tag Filter Change": ITrackEvent;
  "Remove All Filter Tags": ITrackEvent & { removeAllTags: boolean };
  "Add Tag Button Clicked UI": ITrackEvent;
  "Add Tag List Closed UI": ITrackEvent;
  "Add Tag Item Selected UI": ITrackTag;
  "Tag Creation Failure": ITrackTag;
  "Tag On Timeline Selected UI": ITrackTag;
  "Tag On Timeline Deselected UI": ITrackTag;
  "All Tags On Timeline Selected UI": ITrackEvent & { number_of_tags: number };
  "All Tags On Timeline Deselected UI": ITrackEvent & { number_of_tags: number };
  "Tag Menu Opened Clicked UI": ITrackTag;
  "Tag Menu Edit Clicked UI": ITrackTag;
  "Tag Menu Delete Clicked UI": ITrackTag;
  "Tag Menu Delete Tags Clicked UI": ITrackEvent & { tag_ids: number[] };
  "Highlight From Tags Create Highlight": ITrackEvent & { highlightType: "clip" | "player-highlight" };
  "Highlight Generation Failure": ITrackEvent & { highlightType: "clip" | "player-highlight" };
  "Clip From Tag Button Clicked UI": ITrackEvent;
  "Search Tags": { sportType: string; eventName: string; eventID: string };
  "Ask To Join Team Button Clicked": Partial<IAskToJoinData>;
  "Join Team Results Found": { number_of_found_teams: number; team_list: IAskToJoinData["team_list"] };
  "Join Team Button Clicked": undefined;
  "Team Update": Partial<ITrackTeam>;
  "Team Delete": Partial<ITrackTeam>;
  "Organization Update": Partial<ITrackOrganization>;
  "Organization Delete": Partial<ITrackOrganization>;
  "Organization Team Remove": Partial<ITrackTeam>;
  "League Team Remove": Partial<ITrackTeam>;
  "User Update": Partial<{ email: string; name: string; phone: string }>;
  "Download Video Started": ITrackVideo & { video_type?: "hd" | "pano" };
  "Playlist Download Started": ITrackPlaylist & { video_type?: "hd" | "pano" };
  "Export Video Started": ITrackVideo;
  "Export Video Failed": ITrackVideo;
  "Video Shared": ITrackVideo & { share_type: string };
  "Playlist Shared": ITrackPlaylist & { share_type: string };
  "User Left Feedback": { rating: number; user_comment: string };
  "Purchase Breakdown Credit Initiated": Partial<ITrackPurchaseBreakdowns>;
  "Purchase Breakdown Requested": Partial<ITrackPurchaseBreakdowns>;
  "Assign Credit From Balance To Team": ITrackAssignBreakdowns;
  "Withdraw Credit From Team To Balance": ITrackAssignBreakdowns;
  "Send For Breakdown Intent": undefined;
  "Cancel Send For Breakdown Intent": undefined;
  "Send For Breakdown Clicked": ITrackSendForBreakdown;
  "Event Delete": Partial<ITrackEvent>;
  "Opened Videos Page": undefined;
  "Video Filters Applied": ITrackVideoFilters;
  "Match Overview Opened": ITrackEvent & { team1_name: string; team2_name: string };
  "Full Match Video Opened": ITrackEvent;
  "Match Recap Video Opened": ITrackEvent;
  "Match Editor Opened": ITrackEvent & { source: "overview" | "full_match" | "deep_link"; logged_in: boolean };
  "Match Advanced Editor Opened": ITrackEvent;
  "Match Overview Highlights Opened": ITrackEvent;
  "Match Overview Players Opened": ITrackEvent;
  "Match Overview Stats Opened": ITrackEvent;
  "Match Overview Charts Opened": ITrackEvent;
  "Vidswap Match Overview Opened": ITrackEvent;
  "Vidswap Lineup Edit Opened": ITrackEvent;
  "Match Downloaded (HD)": ITrackEvent;
  "Match Downloaded (Pano)": ITrackEvent;
  "Playlist Favorited": ITrackPlaylist;
  "Playlist Unfavorited": ITrackPlaylist;
  "Save Tag to Playlist": ITrackTag;
  "Go to Tag Match Page": ITrackTag;
  "Tag Edited": ITrackTag;
  "Tag Deleted": ITrackTag;
  "Tag Order Changed": ITrackTag & { new_position: number; old_position: number };
  "Video Favorited": ITrackVideo | ITrackPlaylist;
  "Video Unfavorited": ITrackVideo | ITrackPlaylist;
  "Event Favorited": ITrackEvent;
  "Event Unfavorited": ITrackEvent;
  "Clip Favorited": ITrackVideo;
  "Clip Unfavorited": ITrackVideo;
  "System Playlist Opened": ITrackSystemPlaylist;
  "Tags Added to Playlist": ITrackPlaylistTags;
  "Playlist Created": ITrackPlaylist;
  "Create Clip from Tags": ITrackClipFromTags;
  "Tag Played": ITrackTag;
  "Tag Selected": ITrackTag;
  "Tag Deselected": ITrackTag;
  "All Tags Selected": ITrackEvent & { number_of_tags: number };
  "All Tags Deselected": ITrackEvent & { number_of_tags: number };
  "Highlights Page Opened": undefined;
  "My Library Page Opened": undefined;
  "Favorites Page Opened": undefined;
  "Clip Opened": ITrackVideo;
  "Playlist Opened": ITrackPlaylist & { source: string };
  "Event Opened": ITrackEvent;
  "Highlight Opened": ITrackVideo;
  "Player Highlight Opened": ITrackVideo;
  "Teams Page Opened": { super_admin: boolean };
  "Manage Team Page Opened": { user_role?: ITeamUserRole; super_admin: boolean };
  "Team Card Menu Opened": { user_role?: ITeamUserRole; super_admin: boolean };
  "Team Card Menu Item Clicked UI: Edit team": { user_role?: ITeamUserRole; super_admin: boolean };
  "Team Card Menu Item Clicked UI: Manage Rosters": { user_role?: ITeamUserRole; super_admin: boolean };
  "Team Card Menu Item Clicked UI: Leave Team": { user_role?: ITeamUserRole; super_admin: boolean };
  "Team Card Menu Item Clicked UI: View team details": { user_role?: ITeamUserRole; super_admin: boolean };
  "Team Card Menu Item Clicked UI: Invite members": { user_role?: ITeamUserRole; super_admin: boolean };
  "Teams Filters Applied": { super_admin: boolean; searchValue?: string };
  "Video Opened": ITrackVideo & { autoplay?: boolean };
  "Video Played": ITrackVideo;
  "Video Paused": ITrackVideo;
  "Video Audio Muted": ITrackVideo;
  "Video Audio Unmuted": ITrackVideo;
  "Video Volume Changed": ITrackVideo & { volume: number };
  "Video Playback Rate Changed": ITrackVideo & { rate: number };
  "Video Fullscreen Opened": ITrackVideo;
  "Video Fullscreen Closed": ITrackVideo;
  "Match Editor PiP Mode Opened": ITrackVideo;
  "Match Editor PiP Mode Closed": ITrackVideo;
  "Video Seeked": ITrackVideo & { time: number };
  "Video Quality Changed": ITrackVideo & { quality: string };
  "Video Closed": ITrackVideo;
  "Opened Video Source": ITrackVideo & { sourceType?: IPixellotPlayerSourceType };
  "Pano Mode Changed": ITrackVideo & { panoMode: "full" | "restricted" };
  "Moved to Next Tag": ITrackTag;
  "Moved to Previous Tag": ITrackTag;
}

export function formatTrackEvent(event: IEvent): ITrackEvent {
  return {
    event_id: event.eventId,
    event_name: event.title,
    sport_type: event.sport_type,
    event_status: event.status,
    event_type: event.type,
    game_type: event.game_info.type,
    vidswap_id: event.external?.vidswap,
  };
}

export function formatTrackTeam(team: ITeam): Partial<ITrackTeam> {
  return {
    id: team.id,
    color: team.color_home,
    name: team.name,
    sport: team.sport_type,
    shortName: team.name_short,
  };
}
export function formatTrackOrganization(organization: IOrganization): Partial<ITrackOrganization> {
  return {
    id: organization.id,
    name: organization.name,
  };
}

export function formatTrackVideo(video: IBasicVideo | IEvent): ITrackVideo {
  return {
    video_id: video.id,
    event_id: video.eventId,
    video_category: video.type,
    video_name: video.title,
  };
}

export function formatTrackPlaylist(playlist: IPlaylist): ITrackPlaylist {
  return {
    playlist_id: playlist.id,
    playlist_name: playlist.name,
    playlist_type: playlist.type,
    number_of_tags: playlist.size,
  };
}

export function formatTrackSystemPlaylist(systemPlaylist: IPlaylist): ITrackSystemPlaylist {
  return {
    system_playlist_id: systemPlaylist.id,
    system_playlist_name: systemPlaylist.name,
  };
}

export function formatTrackTagsPlaylist(
  tags: ITag[] | IPlaylistItem[],
  playlistId: string | undefined | null,
  eventId: string | undefined,
  source: string | undefined,
  numberOfPlaylistsSelected: number,
): ITrackPlaylistTags {
  return {
    tag_ids: tags.map((tag) => tag.id),
    playlist_id: playlistId,
    event_id: eventId,
    source: source,
    playlist_selected_count: numberOfPlaylistsSelected,
  };
}

export function formatTrackClipFromTags(
  clipName: string,
  numberOfTags: number,
  music: string | null,
  audio: boolean,
  graphics: boolean,
): ITrackClipFromTags {
  return {
    clip_name: clipName,
    number_of_tags: numberOfTags,
    music,
    audio,
    graphics,
  };
}

export function formatTrackPlaylistItem(playlistItem: IPlaylistItem): ITrackTag {  
  return formatTrackTag(playlistItem, playlistItem.playlist_source);
}

export function formatTrackTag(tag: Omit<IPlaylistItem | ITag, "id" | "playlist_source">, event: IEvent): ITrackTag {
  return {
    ...formatTrackEvent(event),
    ...{
      tag_type: tag.type,
      tag_time: tag.startTime,
      tag_end_time: tag.endTime,
      tag_title: tag.title,
      tag_player_number: tag.playerNumber,
    },
  };
}

export interface ISegmentAnalytics {
  page: (path: string, params?: any) => void;
  reset: () => void;
  group: () => void;
  track: <T extends keyof ISegmentTracks>(event: T, params?: ISegmentTracks[T]) => void;
  identify: (
    userId: string,
    user: {
      email: string | null;
      fullName: string | null;
      provider?: "apple" | "google" | "facebook" | "local";
    },
  ) => void;
}

declare let window: Window & typeof globalThis & { analytics?: ISegmentAnalytics };

export default function useSegment(): ISegmentAnalytics {
  const warn = (method: string, args: any[]): void => {
    console.warn(
      `[Segment]: 'analytics.${method}()' disabled since Segment tracking disabled or script does not exist`,
      {
        args,
      },
    );
  };

  function track<T extends keyof ISegmentTracks>(event: T, params?: ISegmentTracks[T]) {
    window.analytics?.track(event, Object.assign({}, params, { client_type: "Ronaldo" }));
  }

  if (window.analytics) {
    return {
      track,
      page: window.analytics.page,
      reset: window.analytics.reset,
      group: window.analytics.group,
      identify: window.analytics.identify,
    };
  }

  return {
    page: (...args: any[]) => warn("page", args),
    reset: (...args: any[]) => warn("reset", args),
    group: (...args: any[]) => warn("group", args),
    track: (...args: any[]) => warn("track", args),
    identify: (...args: any[]) => warn("identify", args),
  };
}
