import { formatAthleteHighlight, formatHighlight } from "~/helpers/format-event";
import type { IAthleteHighlight, IGameHighlight, IResponseHighlight, IResponsePlayerHighlight } from "~/types";

export function getEventHighlights (eventId: string,
  opts?: { accessToken?: string }): Promise<{ data: IGameHighlight[]; total: number }> {
  return useAPI<{ data: IResponseHighlight[] }>(`/api/v1/events/${eventId}/highlights`, {
    ...(opts?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${opts.accessToken}` } }),
    query: {
      relationships: "favorite"
    }
  })
    .then(response => ({ data: response.data.map(formatHighlight), total: response.data.length }));
}

export function getEventHighlightById (highlightId: string, options?: { accessToken?: string }): Promise<IGameHighlight> {
  return useAPI<{ data: IResponseHighlight }>(`/api/v1/events/highlights/${highlightId}`, {
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
    query: {
      relationships: "favorite"
    }
  })
    .then(response => formatHighlight(response.data));
}

export function getEventAthleteHighlightById (highlightId: string, options?: { accessToken?: string }): Promise<IAthleteHighlight> {
  return useAPI<{ data: IResponsePlayerHighlight }>(`/api/v1/player-highlights/${highlightId}`, {
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
    query: {
      relationships: "favorite"
    }
  })
    .then(response => formatAthleteHighlight(response.data));
}

export function getEventAthleteHighlights (
  eventId: string,
  opts: { limit: number; offset: number; team_color?: string; team_player?: string; accessToken?: string }
): Promise<{ data: IAthleteHighlight[]; total: number }> {
  return useAPI<{ data: IResponsePlayerHighlight[]; meta: { total: number } }>(`/api/v1/player-highlights/event/${eventId}`, {
    query: {
      limit: opts.limit,
      offset: opts.offset,
      relationships: "favorite"
    },
    ...(opts?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${opts.accessToken}` } })
  })
    .then(response => ({
      data: response.data.map(formatAthleteHighlight).filter((h) => {
        if (opts.team_color)
          return opts.team_color === h.teamColor;
        if (opts.team_player)
          return opts.team_player === h.playerNumber;
        return true;
      }),
      total: response.meta.total
    }));
}
