<script lang="ts" setup>
import type { PxlIcon } from "~/components/U/Icon";
import { iconBind } from "~/components/U/Icon";

const props = defineProps<{
  icon?: PxlIcon;
  text?: string;
}>();
const slots = useSlots();
</script>

<template>
  <div class="w-full min-h-12 p-4 rounded justify-center items-start flex break-words bg-dark-blue-500 text-white gap-3">
    <div v-if="props.icon" class="bg-dark-blue-400 rounded-full h-10 w-10 grid place-items-center">
      <UIcon v-bind="iconBind(props.icon, { class: 'text-base' })" />
    </div>
    <div v-if="slots.default?.() || props.text" class="grow shrink basis-0 text-xs font-medium">
      <slot>
        {{ props.text }}
      </slot>
    </div>
  </div>
</template>
