
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es",
  "de",
  "fr",
  "it",
  "he",
  "hu",
  "nl",
  "uk-UA"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_en_json" */), cache: true },
{ key: "../locales/en-countries.json", load: () => import("../locales/en-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_en_countries_json" */), cache: true }],
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_es_json" */), cache: true },
{ key: "../locales/es-countries.json", load: () => import("../locales/es-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_es_countries_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_de_json" */), cache: true },
{ key: "../locales/de-countries.json", load: () => import("../locales/de-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_de_countries_json" */), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => import("../locales/fr.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_fr_json" */), cache: true },
{ key: "../locales/fr-countries.json", load: () => import("../locales/fr-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_fr_countries_json" */), cache: true }],
  "it": [{ key: "../locales/it.json", load: () => import("../locales/it.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_it_json" */), cache: true },
{ key: "../locales/it-countries.json", load: () => import("../locales/it-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_it_countries_json" */), cache: true }],
  "he": [{ key: "../locales/he.json", load: () => import("../locales/he.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_he_json" */), cache: true },
{ key: "../locales/he-countries.json", load: () => import("../locales/he-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_he_countries_json" */), cache: true }],
  "hu": [{ key: "../locales/hu.json", load: () => import("../locales/hu.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_hu_json" */), cache: true },
{ key: "../locales/hu-countries.json", load: () => import("../locales/hu-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_hu_countries_json" */), cache: true }],
  "nl": [{ key: "../locales/nl.json", load: () => import("../locales/nl.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_nl_json" */), cache: true },
{ key: "../locales/nl-countries.json", load: () => import("../locales/nl-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_nl_countries_json" */), cache: true }],
  "uk-UA": [{ key: "../locales/uk-UA.json", load: () => import("../locales/uk-UA.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_uk_UA_json" */), cache: true },
{ key: "../locales/uk-UA-countries.json", load: () => import("../locales/uk-UA-countries.json" /* webpackChunkName: "locale__home_runner_work_ronaldo_web_ronaldo_web_locales_uk_UA_countries_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.options.ts?hash=ef6a39e1&config=1" /* webpackChunkName: "__i18n_options_ts_ef6a39e1" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.options.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en",
      "dir": "ltr",
      "files": [
        "locales/en.json",
        "locales/en-countries.json"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "dir": "ltr",
      "files": [
        "locales/es.json",
        "locales/es-countries.json"
      ]
    },
    {
      "code": "de",
      "iso": "de",
      "dir": "ltr",
      "files": [
        "locales/de.json",
        "locales/de-countries.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr",
      "dir": "ltr",
      "files": [
        "locales/fr.json",
        "locales/fr-countries.json"
      ]
    },
    {
      "code": "it",
      "iso": "it",
      "dir": "ltr",
      "files": [
        "locales/it.json",
        "locales/it-countries.json"
      ]
    },
    {
      "code": "he",
      "iso": "he",
      "dir": "rtl",
      "files": [
        "locales/he.json",
        "locales/he-countries.json"
      ]
    },
    {
      "code": "hu",
      "iso": "hu",
      "dir": "ltr",
      "files": [
        "locales/hu.json",
        "locales/hu-countries.json"
      ]
    },
    {
      "code": "nl",
      "iso": "it",
      "dir": "ltr",
      "files": [
        "locales/nl.json",
        "locales/nl-countries.json"
      ]
    },
    {
      "code": "uk-UA",
      "iso": "uk-UA",
      "dir": "ltr",
      "files": [
        "locales/uk-UA.json",
        "locales/uk-UA-countries.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "./locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/en.json"
      },
      {
        "path": "locales/en-countries.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/es.json"
      },
      {
        "path": "locales/es-countries.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/de.json"
      },
      {
        "path": "locales/de-countries.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/fr.json"
      },
      {
        "path": "locales/fr-countries.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/it.json"
      },
      {
        "path": "locales/it-countries.json"
      }
    ]
  },
  {
    "code": "he",
    "iso": "he",
    "dir": "rtl",
    "files": [
      {
        "path": "locales/he.json"
      },
      {
        "path": "locales/he-countries.json"
      }
    ]
  },
  {
    "code": "hu",
    "iso": "hu",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/hu.json"
      },
      {
        "path": "locales/hu-countries.json"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "it",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/nl.json"
      },
      {
        "path": "locales/nl-countries.json"
      }
    ]
  },
  {
    "code": "uk-UA",
    "iso": "uk-UA",
    "dir": "ltr",
    "files": [
      {
        "path": "locales/uk-UA.json"
      },
      {
        "path": "locales/uk-UA-countries.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
