// Record<string, IEventStatus>
export const EVENT_STATUS = {
  LIVE: "live",
  UPCOMING: "upcoming",
  VOD: "vod",
} as const;

// Record<string, TVideoType | 'playlist'>
export const VIDEO_TYPE = {
  EVENT: "event",
  CLIP: "clip",
  PLAYLIST: "playlist",
  GAME_HIGHLIGHT: "highlight",
  ATHLETE_HIGHLIGHT: "player-highlight",
} as const;

export const SOURCE_TYPE = {
  VIDEO: "video",
  PLAYLIST: "playlist",
} as const;

export const PLAYLIST_TYPE = {
  SYSTEM: "system",
  PRIVATE: "private",
};

export const EVENT_TYPES = {
  GAME: "game",
  PRACTICE: "practice",
  SCRIMMAGE: "scrimmage",
  OTHER: "other",
};
