import type { MaybeRefOrGetter } from "@vueuse/shared";

export type TSharingProvider = "Facebook" | "Twitter" | "WhatsApp" | "Email";

export const SHARE_PROVIDER = {
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  WHATSAPP: "WhatsApp",
  EMAIL: "Email",
} as const;

export const SHARE_PROVIDERS: TSharingProvider[] = [
  SHARE_PROVIDER.FACEBOOK,
  SHARE_PROVIDER.TWITTER,
  SHARE_PROVIDER.WHATSAPP,
];

export interface IUseSharingOptions {
  url: string;
  title?: string;
  text?: string;
}

const share_provider_url: Record<TSharingProvider, (data: IUseSharingOptions) => string> = {
  Facebook: (data: IUseSharingOptions) =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(data.url)}&title=${data.title}`,
  Twitter: (data: IUseSharingOptions) =>
    `https://twitter.com/intent/tweet/?url=${encodeURIComponent(data.url)}&text=${data.title}`,
  WhatsApp: (data: IUseSharingOptions) => `https://wa.me/?text=${encodeURIComponent(data.url)}`,
  Email: (data: IUseSharingOptions) =>
    `mailto:?subject=${data.title}&body=${data.url}${data.text ? `\\n${data.text}` : ""}`,
};

export default function useSharing(options: MaybeRefOrGetter<IUseSharingOptions>) {
  const share_options = resolveRef(options);

  const shareProviderUrl = (provider: TSharingProvider): string => {
    return share_provider_url[provider](share_options.value);
  };

  return { shareProviderUrl };
}
