<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    placement?: "top" | "left" | "right" | "bottom";
    isOpened?: boolean;
    hasClose?: boolean;
  }>(),
  {
    placement: "right",
  },
);
const emit = defineEmits<{
  (event: "close"): void;
}>();

const PLACEMENT_CLASS = {
  top: {
    base: ["top-0", "left-0", "right-0"],
    active: ["transform-none"],
    inactive: ["-translate-y-full"],
  },
  right: {
    base: ["right-0", "top-0"],
    active: ["transform-none"],
    inactive: ["translate-x-full"],
  },
  bottom: {
    base: ["bottom-0", "left-0", "right-0"],
    active: ["transform-none"],
    inactive: ["translate-y-full"],
  },
  left: {
    base: ["left-0", "top-0"],
    active: ["transform-none"],
    inactive: ["-translate-x-full"],
  },
};

const activePlacementClass = computed(() => PLACEMENT_CLASS[props.placement]);
const [isOpened, toggle] = useToggle(props.isOpened);

function open() {
  toggle(true);
}

function close() {
  toggle(false);
  emit("close");
}

defineExpose({ close, open });
</script>

<template>
  <!-- drawer init and show -->
  <slot
    name="toggle"
    :toggle="toggle"
  />

  <Teleport to="body">
    <div
      data-test-id="drawer-root"
      class="relative z-30"
    >
      <Transition
        enter-active-class="transition duration-500"
        leave-active-class="transition duration-500"
        appear
      >
        <div
          v-show="isOpened"
          class="fixed inset-0 z-30 transition duration-500 bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
          @click="close()"
        />
      </Transition>
      <div
        id="drawer-navigation"
        :class="[activePlacementClass.base, isOpened ? activePlacementClass.active : activePlacementClass.inactive]"
        class="fixed z-40 h-screen w-full sm:w-[480px] md:transition-transform md:duration-300 bg-white dark:bg-neutral-dark-700 text-black dark:text-white"
        :tabindex="isOpened ? 0 : -1"
      >
        <UButton
          v-if="props.hasClose"
          title="Close"
          variant="link-secondary"
          class="absolute p-0 top-4 right-4"
          icon="close"
          @click="close()"
        />

        <div class="w-full h-full overflow-y-auto">
          <slot
            name="content"
            :close="close"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>
