<script setup lang="ts">
const user = useUser();
</script>


<template>
  <div v-if="user" class="flex w-full items-start gap-3 mb-1 px-4 pt-4 pb-5 border-b border-neutral-light-300 dark:border-neutral-dark-900">
    <UAvatar :alt="user.fullName || 'Anonymous'" :size="40" class="u-user-avatar flex-shrink-0" />
    <div class="flex flex-col gap-1 overflow-hidden">
      <b class="block w-full text-black dark:text-white font-bold text-base leading-[20px] truncate" :title="user.fullName || '---'">
        {{ user.fullName || "---" }}
      </b>
      <span class="block w-full text-neutral-light-800 dark:text-neutral-dark-200 font-medium text-sm leading-[16px] truncate" :title="user.email || '---'">
        {{ user.email }}
      </span>
    </div>
  </div>
</template>
