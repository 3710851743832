<script setup lang="ts">
import type { ComputedRef } from "#imports";
import type { PxlIcon } from "~/components/U/Icon";
import type { inputSizes, inputVariants } from "~/components/U/Input";
import get from "lodash-es/get";

// TODO: Move into separate file when such feature will be supported
interface ICommonInputProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: PxlIcon;
  leadingIcon?: PxlIcon;
  trailingIcon?: PxlIcon;
  trailing?: boolean;
  leading?: boolean;
  hasError?: boolean;
  size?: keyof typeof inputSizes;
  variant?: keyof typeof inputVariants;
  placeholderClass?: string;
}

const emit = defineEmits(["update:modelValue", "change", "focus", "blur"]);
const props = withDefaults(
  defineProps<
    ICommonInputProps & {
      modelValue?: string | number | object | null;
      options: unknown[]; // ------------------------------------

      autosize?: boolean;
      valueAttribute?: string;
      optionAttribute?: string;
    }
  >(),
  {
    size: "lg",
    variant: "default",
    modelValue: "",
    options: () => [],
    optionAttribute: "label",
    valueAttribute: "value",
  },
);
const { emitFormChange, emitFormBlur, formGroup } = useFormGroup();
const inputId = computed(() => formGroup?.name.value || props.name);
const MIN_SELECT_WIDTH = 30;
const selectEl = ref<HTMLSelectElement>();
const selectElWidth = ref(MIN_SELECT_WIDTH);
const hasFocus = ref(false);
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);
const onInput = (event: InputEvent) => emit("update:modelValue", (event.target as HTMLInputElement).value);
const onChange = (event: Event) => {
  emitFormChange();
  emit("change", event);
  adjustWidth();
};

const guessOptionValue = (option: any) => get(option, props.valueAttribute, get(option, props.optionAttribute));
const guessOptionText = (option: any) => get(option, props.optionAttribute, get(option, props.valueAttribute));

const normalizeOption = (option: any) => {
  if (["string", "number", "boolean"].includes(typeof option)) {
    return {
      [props.valueAttribute]: option,
      [props.optionAttribute]: option,
    };
  }

  return {
    ...option,
    [props.valueAttribute]: guessOptionValue(option),
    [props.optionAttribute]: guessOptionText(option),
  };
};

const normalizedOptions = computed(() => props.options.map((option) => normalizeOption(option)));
const normalizedOptionsWithPlaceholder: ComputedRef<
  { [key: string]: any; disabled?: boolean; children: { [key: string]: any; disabled?: boolean }[] }[]
> = computed(() => {
  if (!props.placeholder) return normalizedOptions.value;

  return [
    {
      [props.valueAttribute]: "",
      [props.optionAttribute]: props.placeholder,
      disabled: true,
    },
    ...normalizedOptions.value,
  ];
});

const normalizedValue = computed(() => {
  const normalizeModelValue = normalizeOption(props.modelValue);
  const foundOption = normalizedOptionsWithPlaceholder.value.find(
    (option) => option[props.valueAttribute] === normalizeModelValue[props.valueAttribute],
  );
  if (!foundOption) {
    return "";
  }

  return foundOption[props.valueAttribute];
});

const isPlaceholderSelected = computed(() => props.placeholder && selectEl.value && selectEl.value.selectedIndex === 0);

const onBlur = (event: FocusEvent) => {
  hasFocus.value = false;
  emitFormBlur();
  emit("blur", event);
};
const onFocus = (event: FocusEvent) => {
  hasFocus.value = true;
  emit("focus", event);
};

function adjustWidth() {
  if (!selectEl.value || !props.autosize) {
    return;
  }

  const selectedOption = selectEl.value.options[selectEl.value.selectedIndex];
  if (selectedOption) {
    // Create a temporary element to measure the option's text width
    const tempElement = document.createElement("span");
    tempElement.textContent = selectedOption.text;
    document.body.appendChild(tempElement);
    const optionWidth = tempElement.offsetWidth + 8; // Account for padding
    document.body.removeChild(tempElement);

    selectElWidth.value = Math.max(optionWidth, MIN_SELECT_WIDTH);
  }
}

onMounted(() => adjustWidth());
</script>

<template>
  <UInputControl
    :loading="props.loading"
    :disabled="props.disabled"
    :icon="props.icon"
    :leading-icon="props.leadingIcon"
    :trailing-icon="props.trailingIcon"
    :trailing="props.trailing"
    :leading="props.leading"
    :has-error="props.hasError"
    :has-focus="hasFocus"
    :size="props.size"
    :variant="props.variant"
  >
    <template v-if="$slots.leading" #leading="{ disabled: slotDisabled, loading: slotLoading }">
      <slot name="leading" :disabled="slotDisabled" :loading="slotLoading" />
    </template>

    <template v-if="$slots.trailing" #trailing="{ disabled: slotDisabled, loading: slotLoading }">
      <slot name="trailing" :disabled="slotDisabled" :loading="slotLoading" />
    </template>

    <div class="relative w-full" :style="props.autosize ? { width: selectElWidth + 'px' } : undefined">
      <select
        :id="inputId"
        ref="selectEl"
        :name="name"
        :value="modelValue"
        :required="required"
        :disabled="isDisabled || loading"
        class="u-input-field"
        :class="[isPlaceholderSelected && props.placeholderClass]"
        v-bind="$attrs"
        @input="(e) => onInput(e as InputEvent)"
        @change="onChange"
        @blur="onBlur"
        @focus="onFocus"
      >
        <template v-for="(option, index) in normalizedOptionsWithPlaceholder">
          <optgroup
            v-if="option.children"
            :key="`${option[valueAttribute]}-optgroup-${index}`"
            :value="option[valueAttribute]"
            :label="option[optionAttribute]"
          >
            <option
              v-for="(childOption, index2) in option.children"
              :key="`${childOption[valueAttribute]}-${index}-${index2}`"
              :value="childOption[valueAttribute]"
              :selected="childOption[valueAttribute] === normalizedValue"
              :disabled="childOption.disabled"
              v-text="childOption[optionAttribute]"
            />
          </optgroup>
          <option
            v-else
            :key="`${option[valueAttribute]}-${index}`"
            :value="option[valueAttribute]"
            :selected="option[valueAttribute] === normalizedValue"
            :disabled="option.disabled"
            v-text="option[optionAttribute]"
          />
        </template>
      </select>
      <UIcon
        name="chevron-down"
        class="absolute top-1/2 transform -translate-y-1/2 h-2 w-2 pointer-events-none"
        :class="[
          props.variant === 'blurry' ? 'text-current' : 'text-black dark:text-white',
          props.autosize ? 'rtl:left-0 ltr:right-0' : 'rtl:left-4 ltr:right-4',
        ]"
      />
    </div>
  </UInputControl>
</template>
