<script setup lang="ts">
import type { PxlIconName } from "~/components/U/Icon";
import { UButton } from "#components";

const { t } = useI18n();
const props = defineProps<{
  loading?: boolean;
  disabled?: boolean;
  confirmDisabled?: boolean;
  cancelIcon?: PxlIconName;
  cancelVariant?: InstanceType<typeof UButton>["$props"]["variant"];
  cancelLabel?: string;
  confirmLabel?: string;
  confirmIcon?: PxlIconName;
  onCancelClick: () => void;
  onConfirmClick?: () => void;
}>();
</script>

<template>
  <div class="grid grid-cols-2 gap-4 mt-6">
    <UButton
      size="lg"
      truncate
      :variant="props.cancelVariant || 'secondary'"
      :disabled="props.disabled"
      :label="props.cancelLabel || t('labels.cancel')"
      :icon="props.cancelIcon"
      @click="props.onCancelClick && props.onCancelClick()"
    />
    <UButton
      size="lg"
      type="submit"
      :icon="props.confirmIcon"
      :label="props.confirmLabel || t('labels.submit')"
      :disabled="props.disabled || props.confirmDisabled"
      :loading="props.loading"
      @click="props.onConfirmClick && props.onConfirmClick()"
    />
  </div>
</template>
