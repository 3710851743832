<script setup lang="ts">
const props = defineProps<{
  modelValue?: boolean;
}>();
const emit = defineEmits(["update:modelValue"]);
const isOpen = useVModel(props, "modelValue", emit);

const { t } = useI18n();
const route = useRoute();

const onCancelClick = () => navigateToLogin();
const onConfirmClick = () => navigateTo({ path: "/auth/sign-up/", query: { redirectUrl: route.fullPath } });
</script>

<template>
  <UModalConfirm
    v-model="isOpen"
    :heading="t('labels.auth_required_for_action')"
    :description="t('labels.auth_required_for_action_explanation')"
    icon="profile"
    :cancel-fn="() => onCancelClick()"
    :confirm-fn="() => onConfirmClick()"
    :cancel-label="t('labels.sign_in')"
    :confirm-label="t('labels.sign_up')"
  />
</template>
