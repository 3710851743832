<script setup lang="ts">
export type BaseLogoPropsSize = number | "full" | "auto";
export interface BaseLogoProps {
  src?: string;
  height?: string | number;
  width?: string | number;
  alt?: string;
  fallbackSrc?: string;
}

const isError = ref(false);
const props = withDefaults(defineProps<BaseLogoProps>(), {});
const hasError = computed(() => !props.src || isError.value);
function onImageLoadError() {
  isError.value = true;
}

const imageAttrs = computed(() => ({
  height: props.height,
  width: props.width,
  alt: props.alt,
}));
</script>

<template>
  <img
    v-if="hasError && props.fallbackSrc"
    v-bind="imageAttrs"
    :src="fallbackSrc"
  />
  <IllustrationsEmptyImage v-else-if="hasError" :image-attrs="imageAttrs" />
  <img
    v-else
    v-bind="imageAttrs"
    :src="props.src"
    @error="onImageLoadError()"
  />
</template>
