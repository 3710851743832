export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig().public;
  const gTagId = runtimeConfig.googleAnalyticsKey;

  if (!gTagId) {
    console.warn("[GA4] Google Analytics ID is not provided");
    return;
  }

  // @ts-expect-error: `dataLayer` is not defined
  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line
  function gtag(..._args: any[]) {
    // eslint-disable-next-line prefer-rest-params
    (window as any).dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", gTagId);

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${gTagId}`,
        async: true
      }
    ]
  });
});
