import { datadogRum } from "@datadog/browser-rum";

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig().public;
  const isLocal = window.location.hostname === "localhost" || runtimeConfig.appEnv === "local";

  if (isLocal) {
    console.warn("Datadog tracking is disabled on local environment");
    return;
  }

  datadogRum.init({
    applicationId: "20137ddd-34d3-4f32-92e1-196a0e63b662",
    clientToken: "pube2be8e42b69b20cae95b194c12d3cca3",
    site: "datadoghq.com",
    service: "ronaldo-web",

    // Specify a version number to identify the deployed version of your application in Datadog
    env: runtimeConfig.appEnv,
    version: runtimeConfig.appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
});
