import { convertTimeToSeconds, convertSecondsToTime, getMinutesFromHours } from "~/helpers/format-time";
import formatNumber from "~/helpers/format-number";

interface ItemStatSVG {
  type: "svg";
  displayValue: string;
}

interface ItemStatProgress {
  type: "progress";
  numericValue: number;
  displayValue: string;
  playlistId?: string;
}

interface ItemStatText {
  type: "text";
  displayValue: string;
  playlistId?: string;
}

export type ItemStatValue = ItemStatSVG | ItemStatProgress | ItemStatText;

const getNumericValue = (v?: number | string): number => (Number.isNaN(Number(v)) ? 0 : Number(v));

const getNumberFromPercentageString = (percentageString: string): number => {
  const percent = percentageString.split(" ").find((str) => str.includes("%"));
  const numericValue = percent?.replace("%", "");

  return getNumericValue(numericValue);
};

const getPlaylistIdForStatItem = (value: unknown, options?: getStatValueOptions): string | null => {
  if (!value || !options?.playlistId) return null;

  const isPercent = options.representation === "percent" || String(value).includes("%");
  const isTime = options.representation === "time" || String(value).includes(":");
  const isValidNumber = Number(value) > 0;

  if (isTime) {
    return convertTimeToSeconds(String(value)) === 0 ? null : options.playlistId;
  }
  if (isPercent) {
    return getNumberFromPercentageString(String(value)) === 0 ? null : options.playlistId;
  }

  return isValidNumber ? options.playlistId : null;
};

const getTextStatItem = (value: unknown, options?: getStatValueOptions): ItemStatValue => {
  const item: ItemStatText = { type: "text", displayValue: value ? String(value) : "" };
  const playlistId = getPlaylistIdForStatItem(value, options);

  if (playlistId) item.playlistId = playlistId;

  return item;
};

interface getStatValueOptions {
  playlistId?: string | null;
  representation?: "text" | "number" | "percent" | "time";
}

export default function getStatValue(v: any, options?: getStatValueOptions): ItemStatValue {
  let value = v;

  switch (typeof value) {
    case "object": {
      if (v === null) return getTextStatItem(value, options);

      if (options?.representation === "time") {
        value = convertSecondsToTime(v.number);
      } else {
        value = v.text;
      }
    }
    // eslint-disable-next-line no-fallthrough
    case "string": {
      // First of all check for SVG, no matter what `representation` is
      // Also note: `playlistId` will not be handled for 'SVG' items
      if (value.includes("<svg")) {
        const item: ItemStatSVG = { type: "svg", displayValue: value };
        return item;
      }

      if (options?.representation === "percent") {
        const item: ItemStatProgress = {
          type: "progress",
          numericValue: getNumberFromPercentageString(value),
          displayValue: value,
        };
        const playlistId = getPlaylistIdForStatItem(value, options);
        if (playlistId) item.playlistId = playlistId;
        return item;
      }
      if (options?.representation === "time") {
        const item: ItemStatProgress = { type: "progress", numericValue: 0, displayValue: getMinutesFromHours(value) };
        const playlistId = getPlaylistIdForStatItem(value, options);
        if (playlistId) item.playlistId = playlistId;
        return item;
      }
      if (options?.representation === "number") {
        const item: ItemStatProgress = { type: "progress", numericValue: parseFloat(value), displayValue: formatNumber(value) };
        const playlistId = getPlaylistIdForStatItem(value, options);
        if (playlistId) item.playlistId = playlistId;
        return item;
      }

      // If no `representation` passed - try to parse the value
      // Assuming it's a `number` format
      if (!Number.isNaN(Number(value))) {
        const item: ItemStatProgress = { type: "progress", numericValue: getNumericValue(value), displayValue: value };
        const playlistId = getPlaylistIdForStatItem(value, options);
        if (playlistId) item.playlistId = playlistId;
        return item;
      }
      // Assuming it's a `percentage` format
      if (value.includes("%")) {
        const item: ItemStatProgress = {
          type: "progress",
          numericValue: getNumberFromPercentageString(value),
          displayValue: value,
        };
        const playlistId = getPlaylistIdForStatItem(value, options);
        if (playlistId) item.playlistId = playlistId;
        return item;
      }
      // Assuming it's a `time` format
      if (value.includes(":")) {
        const item: ItemStatProgress = { type: "progress", numericValue: 0, displayValue: getMinutesFromHours(value) };
        const playlistId = getPlaylistIdForStatItem(value, options);
        if (playlistId) item.playlistId = playlistId;
        return item;
      }
    }
    // eslint-disable-next-line no-fallthrough
    case "number": {
      const item: ItemStatProgress = {
        type: "progress",
        numericValue: getNumericValue(value),
        displayValue: Number.isNaN(value) ? "" : String(value),
      };
      const playlistId = getPlaylistIdForStatItem(value, options);
      if (playlistId) item.playlistId = playlistId;
      return item;
    }
    // eslint-disable-next-line no-fallthrough
    default: {
      return getTextStatItem(value, options);
    }
  }
}
