<script lang="ts" setup>
const user = useUser();
</script>

<template>
  <div
    class="default-layout min-h-screen bg-neutral-light-50 dark:bg-neutral-dark-900 dark:text-neutral-dark-200"
    :class="user && 'grid md:grid-cols-[auto_1fr]'"
  >
    <Navbar
      v-if="user"
      class="hidden md:flex"
    />

    <slot />
  </div>
</template>
