import type { DirectusFeatureRule, DirectusRonaldoApplicationConfig } from "~/helpers/format-directus-configs";

export default defineAppConfig({
  useMVP: true,
  useMockData: false,
  debugPano: false,
  features: <DirectusRonaldoApplicationConfig>{
    enable_leagues_flow: true,
    enable_join_team_flow: false,
    enable_playon_organizations: false,
    enable_athlete_add_season: false,
    enable_event_team_stats_tab: true,
    enable_event_stats_export_to_pdf: true,
    enable_event_athlete_stats_tab: true,
    enable_event_add_game_info: false,
    enable_team_manage_rosters_vs_deep_link: false,
    enable_team_overview_tab: true,
    enable_team_stats_tab: true,
    enable_team_athletes_tab: false,
    enable_team_video_upload: true,
    enable_team_video_import_replay: true,
    enable_team_video_import: false,
    enable_league_standings_tab: true,
    enable_league_leaderboards_tab: true,
    enable_league_athletes_tab: false,
    enable_athletes_index_page: true,
    enable_athletes_create_page: false,
    enable_athletes_edit_page: false,
    enable_athletes_delete: false,
    enable_athlete_overview_tab: true,
    enable_athlete_bio_tab: true,
    enable_athlete_videos_tab: true,
    enable_athlete_stats_tab: false,
    enable_athlete_charts_tab: false,
    enable_athletes_merge: false,
  },
  featureRules: [] as DirectusFeatureRule[],
  availableSeasons: [2023, 2024],
  currentSeason: 2024,
});
