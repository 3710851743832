<script setup lang="ts">
const props = defineProps<{
  modelValue: number;
}>();
const emit = defineEmits(["update:modelValue"]);
const selectedRate = useVModel(props, "modelValue", emit);
const hoveredRate = ref(0);
const rootRef = ref(null);
const { isOutside } = useMouseInElement(rootRef);
</script>

<template>
  <div
    ref="rootRef"
    class="flex gap-4"
  >
    <UButton
      v-for="n in 5"
      :key="`rate-${n}`"
      variant="link-primary"
      @click="selectedRate = n"
      @mouseover="hoveredRate = n"
    >
      <RIcon
        name="star"
        class="text-3xl"
        :class="[
          n <= selectedRate ? 'text-blue-500' : 'text-neutral-light-300',
          isOutside ? '' : n <= hoveredRate ? 'stroke-blue-700' : '',
        ]"
      />
    </UButton>
  </div>
</template>
