<script setup lang="ts">
const emit = defineEmits(["update:modelValue", "change"]);
const props = withDefaults(
  defineProps<{
    name?: string;
    value?: boolean | string;
    modelValue?: boolean;
    disabled?: boolean;
    checked?: boolean;
    indeterminate?: boolean;
    required?: boolean;
    label?: string;
  }>(),
  {
    modelValue: false,
  },
);
const { emitFormChange, formGroup } = useFormGroup();
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);

const id = computed(() => props.value ? `${props.name}-${props.value}` : props.name);
const isChecked = useVModel(props, "modelValue", emit);
const onChange = (event: Event) => {
  emit("change", event);
  emitFormChange();
};
</script>

<template>
  <div class="relative flex items-center">
    <div class="relative flex items-center">
      <input
        :id="id"
        v-model="isChecked"
        type="checkbox"
        class="u-checkbox"
        :name="props.name"
        :required="props.required"
        :value="props.value"
        :disabled="isDisabled"
        :checked="props.checked"
        :indeterminate="props.indeterminate"
        @change="onChange"
      />
      <label v-if="isChecked" :for="id">
        <UIcon :for="id" name="check" class="h-2.5 w-2.5 top-[3px] left-[3px] absolute text-white" />
      </label>
    </div>
    <label v-if="props.label || $slots.label" :for="id" :class="['ms-3 text-sm font-medium text-black dark:text-white', props.required && 'u-label-text--required']">
      <slot name="label">{{ props.label }}</slot>
    </label>
  </div>
</template>
