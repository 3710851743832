<script lang="ts" setup>
import { PlaylistsModalCreate } from "#components";
import { PLAYLIST_TYPE } from "~/constants";
import type { IPlaylist } from "~/types";

const props = defineProps<{
  playlist?: IPlaylist | null;
  editPlaylist: (name?: string) => void;
}>();

const modal = useModal();

const { t } = useI18n();

function onClick() {
  modal.open(PlaylistsModalCreate, {
    showInstructions: false,
    playlist: props.playlist!,
    onClose: () => modal.close(),
    "onPlaylist-updated": (name?: string) => props.editPlaylist(name),
  });
}
</script>

<template>
  <UListItem
    icon="edit"
    :text="t('labels.edit')"
    :disabled="playlist?.type === PLAYLIST_TYPE.SYSTEM || !playlist?.permissions?.canEdit"
    @click="onClick"
  />
</template>
