<script setup lang="ts">
import type { LocaleObject } from "@nuxtjs/i18n";

const emit = defineEmits(["back"]);

const { t, locales, locale: currentLocale, setLocale } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const availableLocaleKeys = computed(() => (locales.value as LocaleObject[]).map((l: LocaleObject) => l.code));
const availableLocaleOptions = computed(() =>
  availableLocaleKeys.value.map((k: string) => ({ value: k, label: t(`locales.${k}`) })),
);
const onBackClick = () => emit("back");

function changeLocaleTo(locale: string) {
  setLocale(locale);
  window.location.href = switchLocalePath(locale);
}
</script>

<template>
  <UList>
    <UListItem :on-click-close-menu="false" size="lg" :icon="{ name: 'arrow-right', class: 'transform rotate-180 text-black dark:text-white' }" @click="onBackClick()">
      <span class="font-bold">{{ t("labels.select_your_language") }}</span>
    </UListItem>
    <UDivider lighter />
    <UListItem
      v-for="option in availableLocaleOptions"
      :key="option.value"
      size="md"
      :text="option.label"
      :icon="{ name: option.value === currentLocale ? 'check' : 'empty', class: 'text-blue-500' }"
      @click="changeLocaleTo(option.value)"
    />
  </UList>
</template>
