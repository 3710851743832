export function useZoho() {
    const user = useUser();
    const runtimeConfig = useRuntimeConfig();

    watch(user, () => {
        // @ts-ignore
        if (window?.$zoho?.salesiq?.visitor) {
            // @ts-ignore
            window.$zoho.salesiq.visitor.name(user.value?.fullName);
            // @ts-ignore
            window.$zoho.salesiq.visitor.email(user.value?.email);
        }
    }, { immediate: true });

    return {
        script: runtimeConfig.public.zohoSalesIQKey && {
          hid: "zohosalesiq",
          type: "text/javascript",
          innerHTML: `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${runtimeConfig.public.zohoSalesIQKey}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`
        }
    };
}