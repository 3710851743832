import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export default defineNuxtPlugin(async (nuxtApp) => {
  const user = useUser();
  const token = useUserToken();
  const runtimeConfig = useRuntimeConfig();
  const firebaseConfig = {
    apiKey: runtimeConfig.public.firebaseApiKey,
    authDomain: runtimeConfig.public.firebaseAuthDomain,
    databaseURL: runtimeConfig.public.firebaseDatabaseURL,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  nuxtApp.hooks.hook("app:mounted", () => {
    let timerId: NodeJS.Timeout | null = null;

    function clearSession() {
      clearUser();

      if (auth.currentUser) {
        auth.signOut();
      }
    }

    function onAuthFailure() {
      if (timerId) clearTimeout(timerId);
      timerId = null;

      clearSession();

      return navigateToLogin({ errorCode: "session-expired" });
    }

    function onFirebaseTokenError(error: unknown) {
      console.error(error);
      return null;
    }

    // Listen to Firebase auth changes
    auth.onIdTokenChanged(async (currentUser) => {
      if (!currentUser) {
        clearSession();
        return;
      }

      await updateUser(currentUser);

      // Schedule a refresh before the token expires
      const idTokenResult = await currentUser.getIdTokenResult().catch(onFirebaseTokenError);

      // If we failed to retrieve the token from Firebase - exit.
      if (!idTokenResult) return;

      // @ts-expect-error Date parsing
      const expiresIn = new Date(idTokenResult.expirationTime) - new Date();
      const delay = Math.max(0, expiresIn - 5 * 60 * 1000); // Refresh 5 minutes before expiration

      await preloadUserData();

      // console.log({ expiresIn, delay, expiresInMinutes: expiresIn / 60000, delayInMinutes: delay / 60000 });

      if (!user.value) {
        clearSession();

        navigateToLogin({ errorCode: 440 });
      } else {
        if (timerId) clearTimeout(timerId);

        timerId = setTimeout(async () => {
          // Refresh the token in the background
          const refreshedToken = await currentUser.getIdToken(true).catch(onFirebaseTokenError);

          if (!refreshedToken) return onAuthFailure();

          // Update the user's token in the state if the user is still logged in
          if (token.value) {
            token.value = refreshedToken;
          }
        }, delay);
      }
    });
  });

  return {
    provide: {
      firebaseApp: app,
      firebaseAuth: auth,
    },
  };
});
