import { formatTeamMembersData, formatTeamsData } from "~/helpers/format-team";
import type {
  IOrganization,
  IResponseOrganization,
  IOrganizationPayload,
  IResponseOrganizations,
  IResponseClubs,
  ITeam,
  ITeamMember,
  IResponseClubMemberships,
  ITeamUserRole,
} from "~/types";
import formatOrganization, {
  formatOrganizationPayload,
  formatOrganizationCreatePayload,
} from "~/helpers/format-organization";
import { TEAM_USER_ROLE } from "~/constants";

interface IRequestParams {
  q: string;
  limit: number;
  offset: number;
}

export const getCachedOrganizationsList = useMemoize(
  (query?: Partial<IRequestParams>): Promise<{ data: IOrganization[]; total: number }> => getOrganizationsList(query),
);

export function getOrganizationsList(
  query?: Partial<IRequestParams>,
): Promise<{ data: IOrganization[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<IResponseOrganizations>("/mocks/data/organizations.json").then((res) => ({
      data: res.data.map(formatOrganization),
      total: res.meta.total,
    }));

  return useAPI<IResponseOrganizations>("/api/v1/organizations", { query }).then((res) => {
    const user = useUser();

    if (user.value && !query) {
      user.value.hasOrganizationsAccess = res.data.length > 0;
    }

    return {
      data: res.data.map(formatOrganization),
      total: res.meta.total,
    };
  });
}

export function getOrganizationById(
  organizationId: string,
  options?: { accessToken?: string },
): Promise<IOrganization | null> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<{ data: IResponseOrganization }>("/mocks/data/organizations/123/data.json")
      .then((res) => formatOrganization(res.data))
      .then((res) => new Promise((resolve) => setTimeout(() => resolve(res), 1000)));

  return useAPI<{ data: IResponseOrganization }>(`/api/v1/organizations/${organizationId}`, {
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
  }).then((res) => formatOrganization(res.data));
}

export function getTeamsByOrganizationId(
  organizationId: string,
  query: Partial<IRequestParams>,
): Promise<{ data: ITeam[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<IResponseClubs>("/mocks/data/clubs.json").then((res) => ({
      data: formatTeamsData(res.data),
      total: res.meta.total,
    }));

  return useAPI<IResponseClubs>(`/api/v1/organizations/${organizationId}/teams`, { query }).then((res) => ({
    data: formatTeamsData(res.data),
    total: res.meta.total,
  }));
}

export function createOrganization(payload: IOrganizationPayload): Promise<void> {
  const body = formatOrganizationCreatePayload(payload);

  return useAPI("/api/v1/organizations/", { method: "POST", body }).then(() => {
    getCachedOrganizationsList.clear();
  });
}

export function updateOrganizationById(
  organizationId: string,
  organizationData: Partial<IOrganizationPayload>,
): Promise<void> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve();

  const payload = formatOrganizationPayload(organizationData);

  return useAPI(`/api/v1/organizations/${organizationId}`, { body: payload, method: "PATCH" }).then(() => {
    getCachedOrganizationsList.clear();
  });
}

export function deleteOrganizationById(organizationId: string): Promise<void> {
  return useAPI(`/api/v1/organizations/${organizationId}`, { method: "DELETE" }).then(() => {
    getCachedOrganizationsList.clear();
  });
}

export function removeTeamFromOrganization(organizationId: string, teamId: string): Promise<string> {
  const appConfig = useAppConfig();
  const body = {
    target: "club",
    targetId: teamId,
  };

  if (appConfig.useMockData) return Promise.resolve("Teams removed successfully");

  return useAPI(`/api/v1/organizations/${organizationId}/remove`, { method: "POST", body });
}

export function joinTeamToOrganization(organizationId: string, teamId: string): Promise<string> {
  const appConfig = useAppConfig();
  const body = {
    target: "club",
    targetId: teamId,
  };

  if (appConfig.useMockData) return Promise.resolve("Teams added successfully");

  return useAPI(`/api/v1/organizations/${organizationId}/join`, { method: "POST", body });
}

export function getOrganizationMembersById(
  organizationId: string,
  query?: Partial<IRequestParams>,
): Promise<{ data: ITeamMember[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) {
    return $fetch<IResponseClubMemberships>("/mocks/data/club-members.json").then((res) => {
      const members = formatTeamMembersData(res);
      return { data: members, total: members.length };
    });
  }

  return useAPI<IResponseClubMemberships>(`/api/v1/organizations/${organizationId}/memberships`, {
    query: { ...query, include: "user" },
  }).then((res) => {
    const members = formatTeamMembersData(res);
    return { data: members, total: res.meta.total };
  });
}

export function removeOrganizationMemberById(id: string, memberId: string): Promise<void> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve();

  return useAPI(`/api/v1/organizations/${id}/memberships/${memberId}`, { method: "DELETE" });
}

function getOrganizationInviteTokenAndInviteMembers(
  organizationId: string,
  options: {
    role: ITeamUserRole;
    emails?: string[];
    url?: string;
  },
): Promise<string> {
  const { role, emails, url } = options;
  const appConfig = useAppConfig();
  const INVITE_ROLES: ITeamUserRole[] = [TEAM_USER_ROLE.ADMIN, TEAM_USER_ROLE.COACH, TEAM_USER_ROLE.MEMBER];

  if (!INVITE_ROLES.includes(role))
    return Promise.reject(new Error(`Generation of invite url for a role of "${role}" is not supported yet!`));

  if (appConfig.useMockData) return Promise.resolve(btoa(JSON.stringify({ role })));

  const MEMBERSHIP_ROLE: Partial<Record<ITeamUserRole, string>> = {
    [TEAM_USER_ROLE.ADMIN]: "admin",
    [TEAM_USER_ROLE.COACH]: "coach",
    [TEAM_USER_ROLE.MEMBER]: "viewer",
  } as const;
  const body = { role: MEMBERSHIP_ROLE[role], notifications: emails, url };

  return useAPI<{ data: { token: string } }>(`/api/v1/organizations/${organizationId}/invitations`, {
    method: "POST",
    body,
  }).then((response) => response.data.token);
}

function getOrganizationInviteUrl(organizationId: string, role: ITeamUserRole, token?: string) {
  const baseURL = useRuntimeConfig().public.appBaseURL;
  const url = `${baseURL}/auth/invite/organization?organizationId=${organizationId}&role=${role}&inviteToken={{TOKEN}}`;

  if (token) return url.replace("{{TOKEN}}", token);

  return url;
}

export function inviteOrganizationMembers(
  organizationId: string,
  role: ITeamUserRole,
  emails: string[],
): Promise<string> {
  const url = getOrganizationInviteUrl(organizationId, role);
  return getOrganizationInviteTokenAndInviteMembers(organizationId, { role, emails, url });
}

export function acceptInvitationForOrganization(organizationId: string, invitationToken: string): Promise<void> {
  const appConfig = useAppConfig();
  const token = useUserToken().value;

  if (appConfig.useMockData)
    return new Promise((_resolve, reject) => setTimeout(() => reject(new Error("test")), 2000));

  return useAPI(`/api/v1/organizations/${organizationId}/invitations/accept`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Shared-Authorization": `Bearer ${invitationToken}`,
    },
  }).then(() => {
    // Refresh user's organizations list
    // So in case if user had no organizations we will update those and show him new item in menu
    getCachedOrganizationsList.clear();
    getCachedOrganizationsList();
  });
}
