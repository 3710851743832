<script lang="ts" setup>
export interface InputProps {
  modelValue?: any;
  label?: string;
  type?: "text" | "password" | "number" | "tel" | "color" | "email" | "date";
  placeholder?: string;
  filled?: boolean;
  filledLight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  autofocus?: boolean;
  autocomplete?: string;
  isTextarea?: boolean;
  required?: boolean;
  sensitive?: boolean;
  inputClass?: string;
  iconRight?: string;
  iconRightClass?: string;
  iconLeft?: string;
  iconLeftClass?: string;
  hasHiddenInput?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  max?: number;
  min?: number;
  step?: string;
  maxlength?: number;
}

const inputRef = ref<HTMLInputElement | null>();
const props = withDefaults(defineProps<InputProps>(), {});
const emit = defineEmits(["update:modelValue", "focus", "blur", "clickIconLeft"]);
const data = useVModel(props, "modelValue", emit);
const slots = useSlots();
const classes = computed(() => ({
  [props.inputClass as string]: Boolean(props.inputClass),
  "pxl-input-filled": props.filled,
  "pxl-input-filled-light": props.filledLight,
  "pxl-input-disabled": props.disabled,
  "pxl-input-error": Boolean(props.errorMessage) || Boolean(props.hasError),
  "ltr:pl-10 rtl:pr-10": slots["icon-left"] || props.iconLeft,
  "ltr:pr-10 rtl:pl-10": slots["icon-right"] || props.iconRight || props.sensitive,
}));
const [isPasswordHidden, togglePassword] = useToggle(true);
const inputType = computed(() => (props.sensitive ? (isPasswordHidden.value ? "password" : "text") : props.type));

const focus = () => inputRef.value && inputRef.value.focus();

defineExpose({ focus });

onMounted(() => {
  if (props.autofocus) focus();
});
</script>

<template>
  <div>
    <div
      v-if="props.label"
      class="pxl-input-label"
      :class="{ 'pxl-input-label-required': props.required }"
    >
      <span>{{ props.label }}</span>
    </div>
    <div class="relative">
      <div
        v-if="slots['icon-left'] || props.iconLeft"
        class="absolute inset-y-0 flex items-center ltr:pl-3 rtl:pr-3 ltr:left-0 rtl:right-0"
      >
        <slot name="icon-left">
          <RIcon
            v-if="props.iconLeft"
            :name="props.iconLeft"
            :class="props.iconLeftClass"
            @click="$emit('clickIconLeft')"
          />
        </slot>
      </div>

      <textarea
        v-if="props.isTextarea"
        ref="inputRef"
        :value="data"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :type="inputType"
        class="pxl-input"
        :class="classes"
        :autocomplete="props.autocomplete"
        :required="props.required"
        :autofocus="props.autofocus"
        :maxlength="props.maxlength"
        @input="data = ($event.target as HTMLInputElement)?.value"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />

      <input
        v-else
        v-show="!props.hasHiddenInput"
        ref="inputRef"
        :value="data"
        :step="props.step"
        :maxlength="props.maxlength"
        :max="props.max"
        :min="props.min"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :type="inputType"
        class="pxl-input"
        :class="classes"
        :autocomplete="props.autocomplete"
        :required="props.required"
        :autofocus="props.autofocus"
        @input="data = ($event.target as HTMLInputElement)?.value"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <div
        v-if="props.hasHiddenInput"
        class="pxl-input"
        :class="classes"
      >
        &#12644;
      </div>

      <p
        v-if="props.errorMessage"
        class="pxl-error-text"
      >
        {{ props.errorMessage }}
      </p>

      <div
        v-if="slots['icon-right'] || props.iconRight || props.sensitive"
        class="absolute inset-y-0 flex items-center ltr:pr-3 rtl:pl-3 ltr:right-0 rtl:left-0"
      >
        <slot name="icon-right">
          <RIcon
            v-if="props.iconRight"
            :name="props.iconRight"
            :class="props.iconRightClass"
          />
          <UIcon
            v-else-if="props.sensitive"
            tabindex="0"
            class="cursor-pointer text-neutral-light-700"
            :name="isPasswordHidden ? 'eye' : 'eye-selected'"
            :class="props.iconRightClass"
            @click="togglePassword()"
          />
        </slot>
      </div>
    </div>
  </div>
</template>
