import routerOptions0 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/ronaldo-web/ronaldo-web/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}