import { type UseEventBusReturn, useDebounceFn } from "@vueuse/core";

export interface IForm<T> {
  validate(path?: string, opts?: { silent?: boolean }): Promise<T>;
  clear(path?: string): void;
  errors: Ref<IFormError[]>;
  setErrors(errs: IFormError[], path?: string): void;
  getErrors(path?: string): IFormError[];
}

export type TFormEventType = "blur" | "input" | "change" | "submit";

export interface IFormError {
  path: string;
  message: string;
}
export interface IFormGroup {
  error: ComputedRef<string | boolean | undefined>;
  name: ComputedRef<string | undefined>;
  size: ComputedRef<"sm" | "md" | "lg" | null | undefined>;
  disabled: ComputedRef<boolean | null | undefined>;
}

export interface IFormEvent {
  type: TFormEventType;
  path: string;
}

export function useFormGroup() {
  const formBus = inject<UseEventBusReturn<IFormEvent, string> | undefined>("form-events", undefined);
  const formGroup = inject<IFormGroup | undefined>("form-group", undefined);
  const blurred = ref(false);

  function emitFormEvent(type: TFormEventType, path: string) {
    if (formBus) {
      formBus.emit({ type, path });
    }
  }

  function emitFormBlur() {
    emitFormEvent("blur", formGroup?.name.value!);
    blurred.value = true;
  }

  function emitFormChange() {
    emitFormEvent("change", formGroup?.name.value!);
  }

  const emitFormInput = useDebounceFn(() => {
    if (blurred.value) {
      emitFormEvent("input", formGroup?.name.value!);
    }
  }, 300);

  return {
    emitFormBlur,
    emitFormInput,
    emitFormChange,
    formGroup,
  };
}
