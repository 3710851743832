<script setup lang="ts">
import { EVENT_STATUS, VIDEO_TYPE } from "~/constants";
import type { IBasicVideo, IClip, IEvent, IPlaylist } from "~/types";

const props = defineProps<{
  video?: IEvent | IBasicVideo | null;
  playlist?: null | IPlaylist;
  shareTime?: number;
}>();

const { t } = useI18n();
const user = useUser();
const toast = useToast();

const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;
const isClip = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IClip => vid?.type === VIDEO_TYPE.CLIP;

const isFavorite = computed(() => {
  if (props.playlist) return props.playlist.is_favorite;
  if (props.video) return props.video.is_favorite;

  return false;
});

const isAvailable = computed<boolean>(() => {
  if (props.playlist) return props.playlist.is_favorite || props.playlist.permissions.canAddToFavorites;
  if (props.video) {
    if (isEvent(props.video))
      return props.video.status === EVENT_STATUS.VOD && props.video.permissions.canAddToFavorites;
    if (isClip(props.video)) {
      const isOwnClip = props.video.ownerId === user.value?.atlitId;

      return isOwnClip && props.video.permissions.canAddToFavorites;
    }

    return props.video.permissions.canAddToFavorites;
  }

  return false;
});

function onSuccess() {
  const item = props.playlist || props.video;

  if (item!.is_favorite) {
    toast.success(t("pages.events.removed_favorite_video"));
    item!.is_favorite = false;
  } else {
    toast.success(t("pages.events.added_favorite_video"));
    item!.is_favorite = true;
  }
}

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
}

function onClick() {
  const item = props.playlist || props.video;

  if (!item) return Promise.reject(new Error("Video does not exist. Please try again later."));

  return toggleFavoriteItem(item).then(onSuccess).catch(onError);
}
</script>

<template>
  <UListItem
    v-if="user && isAvailable"
    :icon="isFavorite ? 'bookmark-selected' : 'bookmark'"
    :text="isFavorite ? t('labels.remove_from_favorites') : t('labels.add_to_favorites')"
    :on-click="onClick"
  />
</template>
