import type { MaybeRefOrGetter } from "@vueuse/shared";

export default function useItemsSelect<T extends { [key: string]: any }>(
  data: MaybeRefOrGetter<T[]>,
  primaryKey: string | ((item: T) => any),
) {
  const selectedItemsMap = reactive<Map<string | number, T>>(new Map<string | number, T>()) as Map<string | number, T>;
  const itemsData = resolveRef(data);
  const extractPK = typeof primaryKey === "function" ? primaryKey : (item: T) => item[primaryKey];

  const isAllItemsSelected = computed(() => itemsData.value.every((item: T) => selectedItemsMap.has(extractPK(item))));

  function selectAllItems(state: boolean) {
    if (state) itemsData.value.forEach((item: T) => selectedItemsMap.set(extractPK(item), item));
    else itemsData.value.forEach((item: T) => selectedItemsMap.delete(extractPK(item)));
  }

  function toggleItemSelect(state: boolean, item: any) {
    if (state) selectedItemsMap.set(extractPK(item), item);
    else if (selectedItemsMap.has(extractPK(item))) selectedItemsMap.delete(extractPK(item));
  }

  return { selectedItemsMap, isAllItemsSelected, selectAllItems, toggleItemSelect };
}
