import type { components } from "#build/types/nuxt-open-fetch/atlit";
import type { ILeague, ILeaguePayload } from "~/types";
import { formatSeasonPayload, formatSeasonStringToDate } from "./format-season";

export default function formatLeague (league: components["schemas"]["League"]): ILeague {
  return {
    id: league.id,
    name: league.attributes.name!,
    name_short: league.attributes.shortName,
    type: league.attributes.type!,
    logo_url: league.attributes.logo,
    gender: league.attributes.gender!,
    level: league.attributes.level,
    sport_type: league.attributes.sportType!,
    organization_type: league.attributes.organizationType!,
    fallbackSeason: {
      // @ts-expect-error TODO: Should be added in the `atlit-be` swagger definition
      format: league.attributes.fallbackSeason?.format!,
      // @ts-expect-error TODO: Should be added in the `atlit-be` swagger definition
      startDate: formatSeasonStringToDate(league.attributes.fallbackSeason?.startDate),
    },
    season: {
      format: league.attributes.season?.format,
      startDate: formatSeasonStringToDate(league.attributes.season?.startDate),
    },
    location: {
      country: league.attributes.location?.country,
      state: league.attributes.location?.state,
      city: league.attributes.location?.city
    },
    permissions: {
      canRead: league.meta?.permissions?.includes("read") || false,
      canWrite: league.meta?.permissions?.includes("write") || false,
      canDelete: league.meta?.permissions?.includes("delete") || false,
      availableStats: league.meta?.permissions?.includes("stats:overview") || false
    }
  };
}

export function formatLeagueCreatePayload (data: ILeaguePayload): components["schemas"]["CreateLeagueAttr"] {
  const payload: components["schemas"]["CreateLeagueAttr"] = {
    name: data.name,
    shortName: data.name_short,
    type: data.type!,
    logo: data.logo_file_key || undefined,
    gender: data.gender,
    level: data.level || undefined,
    // @ts-expect-error TODO: Sync sport type types
    sportType: data.sport_type,
    organizationType: data.organization_type,
    season: formatSeasonPayload(data.season)!,
  };

  if (data.location?.country || data.location?.state || data.location?.city) {
    payload.location = {
      country: data.location?.country || undefined,
      state: data.location?.state || undefined,
      city: data.location?.city || undefined
    };
  }

  return payload;
}
export function formatLeagueUpdatePayload (data: Partial<ILeaguePayload>): components["schemas"]["UpdateLeagueAttr"] {
  const payload: components["schemas"]["UpdateLeagueAttr"] = {
    name: data.name,
    shortName: data.name_short,
    type: data.type,
    logo: data.logo_file_key || undefined,
    // Non changeable data:
    // gender: data.gender,
    // sportType: data.sport_type,
    // organizationType: data.organization_type
    level: data.level || undefined,
    season: formatSeasonPayload(data.season),
  };

  if (data.location) {
    payload.location = {
      country: data.location?.country,
      state: data.location?.state?.length === 0 ? null : data.location?.state,
      city: data.location?.city?.length === 0 ? null : data.location?.city
    };
  }

  return payload;
}