export default function formatNumber(value: number | string | null | undefined): string {
  if (value === null || value === undefined || value === "")
    return "-";

  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  if (!isNaN(numericValue))
    return Number.isInteger(numericValue) ? numericValue.toString() : numericValue.toFixed(2);

  return String(value);
}
