/**
 * @param time seconds
 * @returns formatted time in following format
 *  - `01:20:00` - hour:minute:seconds
 *  - `20:00` - minute:seconds
 */
export function formatTime(time: number | undefined | null): string | void {
  if (time === 0 || !time) return "00:00";
  if (!time || time < 0) return;
  const hours: string | number = Math.floor(time / 3600);
  const hoursPadded: string | number = hours < 10 ? `0${hours}` : hours;
  let minutes: string | number = Math.floor((time % 3600) / 60);
  let seconds: string | number = Math.floor(time % 60);
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${hours > 0 ? `${hoursPadded}:` : ""}${minutes}:${seconds}`;
}

export interface RemainingTime {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export const timeFromNow = (date: Date) => new Date(date).getTime() - new Date().getTime();

/**
 * @param date - the date to which remaining time will be calculated
 *
 * @returns object(formatted days, hours, minutes, seconds) with remaining time to a given date
 */
export function getRemainingTime(date: Date): RemainingTime {
  const distance = timeFromNow(date);

  const second = 1000; // miliseconds
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  const days = Math.floor(distance / day);
  const hours = Math.floor((distance % day) / hour);
  const minutes = Math.floor((distance % hour) / minute);
  const seconds = Math.floor((distance % minute) / second);

  return {
    days: days < 10 ? `0${days}` : `${days}`,
    hours: hours < 10 ? `0${hours}` : `${hours}`,
    minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
    seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
  };
}

export function getMinutesFromHours(time: string): string {
  const isValidTimeFormat = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/.test(time);

  if (!isValidTimeFormat) return time;

  const [hours, minutes, seconds] = time.split(":").map(Number);

  return `${hours * 60 + minutes}:${seconds.toString().padStart(2, "0")}`;
}

/**
 * Converts a time string in 'hh:mm:ss' or 'mm:ss' format to the number of seconds.
 * Checks the correctness of the format and validity of the hours, minutes, and seconds values.
 * @param time The time string in 'hh:mm:ss' or 'mm:ss' format.
 * @returns The number of seconds or `undefined` if the format is incorrect or values are invalid.
 */
export function convertTimeToSeconds(time: string): number | undefined {
  const timeParts = time.split(":");

  if (timeParts.length !== 2 && timeParts.length !== 3) return;

  if (timeParts.length === 2) {
    const [minutes, seconds] = timeParts.map(part => parseInt(part, 10));

    if (isNaN(minutes) || isNaN(seconds) || minutes >= 60 || seconds >= 60) return;

    return minutes * 60 + seconds;
  } else {
    const [hours, minutes, seconds] = timeParts.map(part => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || hours >= 24 || minutes >= 60 || seconds >= 60) return;

    return hours * 3600 + minutes * 60 + seconds;
  }
}

export function convertSecondsToTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${minutes}:${formattedSeconds}`;
}
