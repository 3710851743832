declare global {
  const branch: {
    init: (key: string, cb: (err: unknown, data: unknown) => void) => void;
    link: (link: BranchIOLinkData, cb: (err?: unknown, link?: string) => void) => void;
    setBranchViewData: (link: BranchIOLinkData) => void;
    data: (cb: (err?: unknown, data?: unknown) => void) => void;
    first: (cb: (err?: unknown, data?: unknown) => void) => void;
  };
}

interface BranchIOLinkData {
  campaign?: string;
  channel?: string;
  feature?: string;
  stage?: string;
  tags?: string[];
  /**
   * Reference for link data: https://help.branch.io/developers-hub/docs/web-full-reference#linkdata-callback
   */
  data: {
    [key: string]: string | boolean | number | undefined;
    $og_title: string;
    $og_description?: string;
    $og_image_url?: string;
    $og_video?: string;
    $og_url?: string;
    $og_redirect?: string;
  };
}

export function useBranchIO () {
  function createBranchIOLink (linkData: BranchIOLinkData): Promise<string> {
    return new Promise((resolve) => {
      const fallbackUrl = linkData.data.$desktop_url as string;

      const handleError = (error?: unknown) => {
        console.warn(error || "An error occurred while loading Branch SDK");
        resolve(fallbackUrl);
      };

      if (!branch)
        return handleError("Branch SDK is not initialized");

      const timeoutId = setTimeout(handleError, 5000);

      branch.link(linkData, (err, link) => {
        clearTimeout(timeoutId);

        err ? handleError(err) : resolve(link || "");
      });
    });
  }

  return { link: createBranchIOLink };
}
