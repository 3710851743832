<script setup lang="ts">
import { formatTime } from "~/helpers/format-time";

const props = withDefaults(
  defineProps<{
    url: string;
    time?: number;
    text?: string;
    hasEmailProvider?: boolean;
    hasProviders?: boolean;
  }>(),
  {
    hasProviders: true,
  },
);

const emit = defineEmits<{
  (event: "share", provider: string): void;
}>();
const toast = useToast();
const isTimeShared = ref(false);
const shareUrl = computed(() => {
  if (isTimeShared.value && props.time) return `${props.url}?time=${props.time}`;
  return props.url;
});
const shareData = computed(() => ({ url: shareUrl.value, title: props.text || "" }));
const { shareProviderUrl } = useSharing(shareData);
const shareProviders = computed(() => {
  if (props.hasEmailProvider) return SHARE_PROVIDERS.concat(SHARE_PROVIDER.EMAIL);
  return SHARE_PROVIDERS;
});

const { copy, isSupported: isCopySupported } = useClipboard({ source: shareUrl.value });

// determine if the URL has been shortened by Branch.io or not. Non-short URLs can't be shared on Facebook
const isShortUrl = computed(() => {
    if (!shareUrl.value) {
        return false;
    }

    const shareUrlObj = new URL(shareUrl.value);
    const params = new URLSearchParams(shareUrlObj.search);

    return params.has("accessToken");
});

function onClipboardCopyClick() {
  copy(shareUrl.value).then(() => toast.success("URL copied successfully"));
  onShare("clipboard copy");
}

function onShare(provider: string) {
  emit("share", provider);
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <div v-if="props.hasProviders" class="flex justify-center gap-8">
      <template v-for="provider in shareProviders" :key="provider">
        <a
            v-if="provider !== 'Facebook' || !isShortUrl"
            :href="shareProviderUrl(provider)"
            target="_blank"
            @click="onShare(provider)"
        >
            <RIcon :name="`share/${provider.toLowerCase()}`" class="text-[56px] inline-block" filled />
            <p class="mt-2 font-medium text-xs text-neutral-light-800 text-center">
            {{ provider }}
            </p>
        </a>
      </template>
    </div>

    <UInput :model-value="shareUrl" :disabled="!shareUrl" :trailing-icon="{ name: 'copy', disabled: !isCopySupported, onClick: onClipboardCopyClick }" readonly />
    <div v-if="props.time && shareUrl" class="flex items-center gap-2 dark:text-white">
      <UCheckbox v-model="isTimeShared" name="share-with-time" :label="`Start at ${formatTime(props.time)}`" />
    </div>
  </div>
</template>
