import revive_payload_client_xR0eO7tJVu from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qahS2bNOwo from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TnnpC80bq6 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ggSyJ8B1LJ from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_3ilgSAqBYY from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ronaldo-web/ronaldo-web/.nuxt/components.plugin.mjs";
import prefetch_client_VtadBCM3jQ from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DFNiMarFXh from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-directus@5.6.0_rollup@4.14.3/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_jerVAGZPUl from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-bugsnag@7.2.3_rollup@4.14.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_1MLiYdr7hL from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.14.3_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_dwnxlppnkV from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3_rollup@4.14.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_0nsOfzgWyS from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-open-fetch@0.2.2_rollup@4.14.3/node_modules/nuxt-open-fetch/dist/runtime/plugin.mjs";
import chunk_reload_client_oFF5JCOJ8Z from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_sass@1.72.0_t_i3zp3q2tygzv32q76atwwtpi4y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_client_vQNOeunMWK from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/auto-animate.client.ts";
import datadog_client_cfAAU11B0P from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/datadog.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/firebase.client.ts";
import floating_vue_BiOD74u9sH from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/floating-vue.ts";
import google_analytics_client_KruZkwwzId from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/google-analytics.client.ts";
import middleware_global_fxkQLky8iN from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/middleware.global.ts";
import modals_RaVbrZQ2Fe from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/modals.ts";
import openFetch_GtVg5tZhau from "/home/runner/work/ronaldo-web/ronaldo-web/plugins/openFetch.ts";
export default [
  revive_payload_client_xR0eO7tJVu,
  unhead_qahS2bNOwo,
  router_TnnpC80bq6,
  payload_client_ggSyJ8B1LJ,
  check_outdated_build_client_3ilgSAqBYY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VtadBCM3jQ,
  plugin_DFNiMarFXh,
  plugin_jerVAGZPUl,
  i18n_1MLiYdr7hL,
  plugin_client_dwnxlppnkV,
  plugin_0nsOfzgWyS,
  chunk_reload_client_oFF5JCOJ8Z,
  auto_animate_client_vQNOeunMWK,
  datadog_client_cfAAU11B0P,
  firebase_client_zXNDa0wxFH,
  floating_vue_BiOD74u9sH,
  google_analytics_client_KruZkwwzId,
  middleware_global_fxkQLky8iN,
  modals_RaVbrZQ2Fe,
  openFetch_GtVg5tZhau
]