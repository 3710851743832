import type {
  IRequestPayloadCreateClub,
  IResponseAtlitUser,
  IResponseClub,
  IResponseClubMemberships,
  IResponseUserClubMembership,
  ITeam,
  ITeamMember,
  ITeamPayload,
  ITeamUserRole,
  ITeamMemberPermissions,
  IResponseTeamAthleteData,
  ITeamAthleteItem,
  IUnofficialTeamPayload,
} from "~/types";
import { TEAM_USER_ROLE, TEAM_USER_STATUS } from "~/constants";
import formatOrganization from "./format-organization";
import formatLeague from "./format-league";
import { formatAthleteRoster } from "./format-athlete";
import { formatSeasonPayload, formatSeasonStringToDate } from "./format-season";

const MEMBERSHIP_ROLE = {
  owner: TEAM_USER_ROLE.OWNER,
  admin: TEAM_USER_ROLE.ADMIN,
  coach: TEAM_USER_ROLE.COACH,
  viewer: TEAM_USER_ROLE.MEMBER,
  athlete: TEAM_USER_ROLE.ATHLETE,
} as const;
const MEMBERSHIP_STATUS = {
  joined: TEAM_USER_STATUS.ACTIVE,
  invited: TEAM_USER_STATUS.INVITED,
  pending: TEAM_USER_STATUS.PENDING,
} as const;

export function getFullName(payload: { firstName?: string; middleName?: string; lastName?: string }): string {
  return [payload.firstName, payload.middleName, payload.lastName].filter(Boolean).join(" ") || "---";
}

export function getUserRoleByMemberShip(membership: IResponseUserClubMembership): ITeamUserRole {
  if (membership.attributes.status === "pending") return TEAM_USER_ROLE.PENDING_MEMBER;
  else if (MEMBERSHIP_ROLE[membership.attributes.role]) return MEMBERSHIP_ROLE[membership.attributes.role];

  return TEAM_USER_ROLE.NOT_MEMBER;
}

export function formatTeamsData(data: IResponseClub[], included?: IResponseUserClubMembership[]) {
  const teams = data.map((club) => {
    const membership = included?.find((membership) => membership.attributes.clubId === club.id);

    return formatTeam(club, membership);
  });

  return teams;
}

export function formatTeamToClubPayload(team: Partial<ITeamPayload>): Partial<IResponseClub["attributes"]> {
  const payload: Partial<IResponseClub["attributes"]> = {
    name: team.name,
    shortName: team.name_short,
    logo: team.logo_file_key,
    gender: team.gender,
    season: formatSeasonPayload(team.season),
  };

  if (team.color_home || team.color_away) payload.jersey = { home: team.color_home, away: team.color_away };

  if (team.location) {
    payload.location = {
      // @ts-expect-error optional
      country: team.location?.country,
      // @ts-expect-error optional
      state: team.location?.state,
      // @ts-expect-error optional
      city: team.location?.city,
    };
  }

  return payload;
}

export default function formatTeam(
  club: IResponseClub,
  userMembership?: IResponseUserClubMembership,
): ITeam {
  const canInviteViewer = club.meta?.permissions?.includes("members:invite:viewer") || false;
  const canInviteAdmin = club.meta?.permissions?.includes("members:invite:admin") || false;
  const canInviteCoach = club.meta?.permissions?.includes("members:invite:coach") || false;
  const availableInviteRoles = [canInviteAdmin && "admin", canInviteCoach && "coach", canInviteViewer && "viewer"].filter(Boolean);
  const membership = userMembership || (Array.isArray(club.relationships?.memberships) ? club.relationships?.memberships[0] : club.relationships?.memberships);

  const userRole = membership ? getUserRoleByMemberShip(membership) : TEAM_USER_ROLE.NOT_MEMBER;

  const team: ITeam = {
    id: club.id,
    vidswapId: club.attributes.external?.vidswap,
    type: club.attributes.type,
    name: club.attributes.name,
    name_short: club.attributes.shortName,
    logo_url: club.attributes.logo,
    sport_type: club.attributes.sportType,
    gender: club.attributes.gender,
    level: club.attributes.level,
    fallbackSeason: {
      // @ts-expect-error TODO: Should be added in the `atlit-be` swagger definition
      format: club.attributes.fallbackSeason?.format!,
      // @ts-expect-error TODO: Should be added in the `atlit-be` swagger definition
      startDate: formatSeasonStringToDate(club.attributes.fallbackSeason?.startDate),
    },
    season: {
      format: club.attributes.season?.format,
      startDate: formatSeasonStringToDate(club.attributes.season?.startDate),
    },
    color_home: club.attributes.jersey?.home,
    color_away: club.attributes.jersey?.away,
    user_role: userRole,
    location: {
      country: club.attributes.location?.country,
      state: club.attributes.location?.state,
      city: club.attributes.location?.city,
    },
    basic_assigned: club.relationships?.breakdowns?.basic?.assigned || 0,
    advanced_assigned: club.relationships?.breakdowns?.advanced?.assigned || 0,
    seasonsHistoryMap: new Map(),
    permissions: {
      canRead: club.meta?.permissions?.includes("read") || false,
      canWrite: club.meta?.permissions?.includes("write") || false,
      canReadMembers: club.meta?.permissions?.includes("members:read") || false,
      canEditMembers: club.meta?.permissions?.includes("write") || false,
      canInvite: (role) => club.meta?.permissions?.includes(`members:invite:${role}`) || false,
      canLeave: club.meta?.permissions?.includes("leave") || false,
      canDelete: club.meta?.permissions?.includes("delete") || false,
      canOpenDeepLinkRoster: club.meta?.permissions?.includes("deeplink:roster") || false,
      canOpenDeepLinkHome: club.meta?.permissions?.includes("deeplink:home") || false,
      availableStats: club.meta?.permissions?.includes("stats:overview") || false,
      availableInviteRoles: availableInviteRoles as ITeamUserRole[],
      canPurchaseBreakdowns: userRole === TEAM_USER_ROLE.OWNER || userRole === TEAM_USER_ROLE.ADMIN || userRole === TEAM_USER_ROLE.COACH,
      canSendBreakdowns: userRole === TEAM_USER_ROLE.OWNER,
      canReadAthletes: club.meta?.permissions?.includes("athletes:read") || false,
    },
  };

  let organization = club.attributes.organization || club.relationships?.organization;
  
  if (club.relationships?.organizations) {
    if (Array.isArray(club.relationships.organizations)) {
      organization = club.relationships.organizations[0];
    } else {
      organization = club.relationships.organizations;
    }
  }

  if (organization) {
    team.organization = formatOrganization(organization);
  }

  if (club.relationships?.leagueclubs) {
    if (Array.isArray(club.relationships.leagueclubs)) {

      club.relationships.leagueclubs.forEach((cl) => {
        if (!cl.attributes.league) return;
        const league = formatLeague(cl.attributes.league);
        cl.attributes.seasons.forEach((s) => team.seasonsHistoryMap!.set(s, league));
      });
    } else {
      [club.relationships.leagueclubs].forEach((cl) => {
        if (!cl.attributes.league) return;
        const league = formatLeague(cl.attributes.league);
        cl.attributes.seasons.forEach((s) => team.seasonsHistoryMap!.set(s, league));
      });
    }
  }

  if (club.relationships?.rosterAthlete) {
    team.athleteRoster = formatAthleteRoster(club.relationships?.rosterAthlete);
  }

  return team;
}

export function formatTeamMembersData(res: IResponseClubMemberships): ITeamMember[] {
  if (!res.included) return [];

  const members = res.data.map((membership) => {
    const user = res.included!.find((user) => user.id === membership.attributes.userId);

    return formatTeamMember(membership, user);
  });

  return members;
}

export function getPermissionsByMembership(membership: IResponseUserClubMembership): ITeamMemberPermissions {
  return {
    canWrite: membership.meta?.permissions?.includes("write") || false,
    canRead: membership.meta?.permissions?.includes("read") || false,
    canSetAthlete: membership.meta?.permissions?.includes("set:athlete") || false,
    canSetViewer: membership.meta?.permissions?.includes("set:viewer") || false,
    canSetAdmin: membership.meta?.permissions?.includes("set:admin") || false,
    canSetCoach: membership.meta?.permissions?.includes("set:coach") || false,
    canSetOwner: membership.meta?.permissions?.includes("set:owner") || false,
    canDelete: membership.meta?.permissions?.includes("delete") || false,
  };
}

export function formatTeamMember(membership: IResponseUserClubMembership, user?: IResponseAtlitUser): ITeamMember {
  return {
    uid: user?.attributes.uid!,
    atlitUserId: user?.id,
    membershipId: membership.id,
    athleteId: membership.attributes.athleteId,
    email: user?.attributes.email || membership.attributes.profile?.email || "-",
    fullName: user?.attributes.fullName || getFullName(membership.attributes.profile || {}),
    user_role: getUserRoleByMemberShip(membership),
    user_status: MEMBERSHIP_STATUS[membership.attributes.status],
    permissions: getPermissionsByMembership(membership),
  };
}

export function formatTeamCreatePayload(payload: ITeamPayload | IUnofficialTeamPayload): IRequestPayloadCreateClub {
  return {
    type: payload.type || "primary",
    name: payload.name,
    shortName: payload.name_short || undefined,
    sportType: payload.sport_type,
    logo: payload.logo_file_key || undefined,
    jersey: {
      home: payload.color_home,
      away: payload.color_away,
    },
    gender: payload.gender,
    location: payload.location ? {
      country: payload.location.country,
      state: payload.location.state || undefined,
      city: payload.location.city || undefined,
    } : undefined,
    season: formatSeasonPayload(payload.season),
  };
}

export function formatAthleteRosterItem(item: IResponseTeamAthleteData): ITeamAthleteItem {
  return {
    id: item.id,
    athleteId: item.attributes?.athleteId,
    name: getFullName(item.relationships.membership?.attributes?.profile || {}),
    email: item.relationships.membership?.attributes?.profile?.email,
    status: item.attributes?.status,
    inviteStatus: item.relationships.membership?.attributes?.status,
    jersey: item.attributes?.jersey,
    positions: item.attributes?.positions,
    // @ts-expect-error Should be updated in swagger
    logo_url: item.relationships.membership?.attributes.profile?.logo,
    membership: item.relationships.membership,
    permissions: {
      canWrite: item.meta?.permissions?.includes("write") || false,
    },
  };
}
