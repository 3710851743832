import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";
import type { IPlayerState } from "~/modules/pixellot-sdk";
import type { IBasicVideo, IEvent, IPlaylist } from "~/types";

const [provideFloatingPlayer, useFloatingPlayer] = useSingleton<{
  open: (v: IPlaylist | IEvent | IBasicVideo | null) => void;
  close: () => void;
  togglePiPMode: () => void;
  video: Ref<IEvent | IBasicVideo | null>;
  player: Ref<IPixellotPlayer | null>;
  playerState: Ref<IPlayerState | null>;
}>();

export { provideFloatingPlayer, useFloatingPlayer };
