<script setup lang="ts">
import type { VideoJsPlayer as IPixellotPlayer, IVideoTrimAPI } from "@pixellot/web-sdk";
import type { IPlayerState } from "../util/state";
import { ref, onMounted, onBeforeUnmount } from "vue";

const props = defineProps<{
  player: IPixellotPlayer;
  playerState: IPlayerState;
}>();

const API = ref<IVideoTrimAPI | null>(null);
const root = ref<HTMLElement | null>(null);
const selectedRange = ref<readonly [number, number]>();
const resizeObserver = ref<ResizeObserver | null>(null);

function updateMaskOffset() {
  const slider = API.value?.getSlider();

  if (!slider) return;

  // @ts-expect-error Need NoUISlider types here
  const [start, end] = slider.getPositions();
  const containerEl: HTMLDivElement | null = document.querySelector(".pxl-video-clipping .noUi-connects");

  if (!containerEl) return;

  containerEl?.style.setProperty("--mask-offset-left", start + "%");
  containerEl?.style.setProperty("--mask-offset-right", end + "%");
}

/**
 * Returns the width that each frame should have
 * @param numberOfPips The number of pips on the slider
 */
function getFrameWidth(numberOfPips: number): number {
  return root.value && resizeObserver.value ? getContentWidth(root.value as HTMLElement) / (numberOfPips - 1) : 130;
}

/**
 * Returns the 'content' width of an element
 * @param element The element to get the content width of
 */
function getContentWidth(element: HTMLElement): number {
  const styles = window.getComputedStyle(element);

  return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
}

function renderFrames() {
  // @ts-expect-error 'state' is not defined in type of videojs.Plugin
  const thumbOptions = props.player.spriteThumbnails().state.options;
  const rows = thumbOptions.rows as number;
  const cells = thumbOptions.cells as number;
  const pips = document.querySelectorAll<HTMLDivElement>(".pxl-video-clipping .noUi-value");
  const frameWidth = getFrameWidth(pips.length);
  pips.forEach((p, index, arr) => {
    // Exclude the last thumbnail since there is not enough space for it on UI.
    if (index === arr.length - 1) return;

    const timestamp = Number(p.getAttribute("data-value"));
    const imgWidth = thumbOptions.width;
    const imgHeight = thumbOptions.height;

    const thumbnailIndex = Math.round(timestamp / thumbOptions.interval);
    const rowIndex = Math.floor(thumbnailIndex / cells);
    const cellIndex = thumbnailIndex % cells;
    const bgSize = `${imgWidth * cells}px ${imgHeight * rows}px`;

    const offsetTop = rowIndex * imgHeight;
    const offsetLeft = cellIndex * imgWidth;

    p.style.setProperty("--pip-width", getComputedStyle(p).width);
    p.style.setProperty("--frame-width", `${frameWidth}px`);
    p.style.setProperty("--frame-content", "''");
    p.style.setProperty("--frame-background-image", `url(${thumbOptions.url})`);
    p.style.setProperty("--frame-background-size", bgSize);
    p.style.setProperty("--frame-background-position", `-${offsetLeft}px -${offsetTop}px`);
  });
}

/**
 * Synchronizes the inner slider values with the player slider values
 */
function onInnerTrimChanged() {
  if (!API.value) return;

  selectedRange.value = API.value.getRange();

  updateMaskOffset();
}

/**
 * Synchronizes the player slider values with the inner slider values
 */
function onPlayerTrimChanged() {
  if (!API.value) return;

  reloadInnerTrim();
}

function reloadInnerTrim() {
  if (!root.value) return;

  if (API.value) {
    API.value.destroy();
    API.value = null;
  }

  const [playerStart, playerEnd] = props.player.trimAPI.getRange();

  API.value = props.player.createAPI({
    sliderRange: { min: playerStart, max: playerEnd },
    rootElement: root.value as HTMLElement,
    showPips: true,
    onChanged: onInnerTrimChanged,
  });

  API.value.show();

  renderFrames();
  onInnerTrimChanged();
}

function setRange(start: number, end: number) {
  API.value?.setRange(start, end);
}

/**
 * Since this component introduces another slider
 * we're reloading the existing timeline slider with the new options
 * so both sliders will be synchronized and work in tandem
 */
function reloadPlayerTrimAPI() {
  let playerSliderStart;
  if (props.player.duration() <= 120) {
    playerSliderStart = [0, props.player.duration()] as [number, number];
  }

  props.player.trimAPI.updateOptions({
    sliderStart: playerSliderStart,
    sliderBehaviour: "drag-fixed",
  });
}

/**
 * Creates a ResizeObserver for the given element which will call renderFrames on resize
 * @param element The element to observe for resize
 */
function initResizeObserver(element: HTMLElement) {
  destroyResizeObserver();

  if (window.ResizeObserver) {
    resizeObserver.value = new ResizeObserver(() => {
      renderFrames();
    });

    resizeObserver.value.observe(element);
  }
}

/**
 * Disconnects the ResizeObserver if it exists
 */
function destroyResizeObserver() {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
    resizeObserver.value = null;
  }
}

onMounted(() => {
  reloadPlayerTrimAPI();
  reloadInnerTrim();

  props.player.one("loadedmetadata", () => {
    reloadPlayerTrimAPI();
    reloadInnerTrim();
  });

  props.player.trimAPI.updateOptions({
    createRangeIndicators: false,
  });

  props.player.on("trim:changed", onPlayerTrimChanged);

  if (root.value) {
    initResizeObserver(root.value as HTMLElement);
  }
});

onBeforeUnmount(() => {
  props.player.off("trim:changed", onPlayerTrimChanged);
  API.value?.destroy();
  destroyResizeObserver();
});

defineExpose({ selectedRange, setRange });
</script>

<template>
  <div
    ref="root"
    class="pxl-video-clipping"
  />
</template>

<style>
.pxl-video-clipping {
  padding: 50px 24px 0;
  overflow: hidden;
}

.pxl-video-clipping .noUi-base,
.pxl-video-clipping .noUi-connects {
  width: calc(100% + 2px); /* give the mask a 1px border at each side */
  margin-left: -1px;
}

.pxl-video-clipping .noUi-horizontal {
  height: 64px;
  border: none;
  box-shadow: unset;
}

.pxl-video-clipping .noUi-horizontal .noUi-connects::before,
.pxl-video-clipping .noUi-horizontal .noUi-connects::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.56);
}

.pxl-video-clipping .noUi-horizontal .noUi-connects::before {
  left: 0;
  width: var(--mask-offset-left);
}

.pxl-video-clipping .noUi-horizontal .noUi-connects::after {
  right: 0;
  width: calc(100% - var(--mask-offset-right));
}

.pxl-video-clipping .noUi-horizontal .noUi-connect {
  background-color: transparent;
  border: 2px solid var(--vjs-theme-pxl--primary);
}

.pxl-video-clipping .noUi-horizontal .noUi-handle {
  height: 64px;
  width: 16px;
  top: 0;
  right: -8px;
  border: none;
  box-shadow: unset;
  background: var(--vjs-theme-pxl--primary);
}

.pxl-video-clipping .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -8px;
  right: auto;
}

.pxl-video-clipping .noUi-horizontal .noUi-handle::before {
  height: 24px;
  width: 2px;
  border-radius: 1px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pxl-video-clipping .noUi-horizontal .noUi-handle::after {
  display: none;
}

.pxl-video-clipping .noUi-horizontal .noUi-handle.noUi-handle-lower {
  border-radius: 4px 0px 0px 4px;
}

.pxl-video-clipping .noUi-horizontal .noUi-handle.noUi-handle-upper {
  border-radius: 0px 4px 4px 0px;
}

.pxl-video-clipping .noUi-pips-horizontal {
  top: -50px;
}

.pxl-video-clipping .noUi-value::before {
  content: var(--frame-content);
  position: absolute;
  top: 30px;
  left: calc(var(--pip-width) / 2); /* reset the thumbnail's position to the centre of the pip's timestamp text */
  width: var(--frame-width);
  height: 60px;
  background: #000;
  /* border: 1px solid red; */
  background-image: var(--frame-background-image);
  background-size: var(--frame-background-size);
  background-position: var(--frame-background-position);
}
</style>
