import type { DirectusRonaldoApplicationConfig, TEnvironment } from "~/helpers/format-directus-configs";
import type { IUser, TSportType } from "~/types";

interface IsFeatureEnabledOptions {
    user?: MaybeRef<IUser | null>;
    sport_type?: TSportType;
    check?: () => boolean;
}

export function isFeatureEnabled (name: keyof DirectusRonaldoApplicationConfig, options?: IsFeatureEnabledOptions): boolean {
    const user = unref(options?.user);
    const appEnv = useRuntimeConfig().public.appEnv as TEnvironment;
    const appConfig = useAppConfig();
    const feature_rule = appConfig.featureRules.find(r => r.feature_name === name);

    if (feature_rule && feature_rule.enable_for_environments.includes(appEnv)) {
        // If user is super admin and rule applies to him - enable feature
        if (feature_rule.enable_for_super_admins && user?.hasSuperAdminRole) {
            return true;
        }

        // If both rules for user email and sport type are applied than those will have affect by each other
        if (feature_rule.enable_for_users?.length && feature_rule.enable_for_sport_type?.length && options?.sport_type) {
            const email = user?.email || "";
            return feature_rule.enable_for_users.includes(email) && feature_rule.enable_for_sport_type.includes(options.sport_type);
        }
        
        if (feature_rule.enable_for_users?.length) {
            const email = user?.email || "";
            return feature_rule.enable_for_users.includes(email);
        }

        if (feature_rule.enable_for_sport_type?.length && options?.sport_type) {
            return feature_rule.enable_for_sport_type.includes(options.sport_type);
        }

        if (options?.check) {
            return options.check();
        }

        return false;
    }

    if (options?.check) {
        return options.check();
    }

    return appConfig.features[name];
}
