import formatDate from "./format-date";

type SeasonFormat = "single-year" | "cross-year";
type SeasonPayload = undefined | { format: SeasonFormat; startDate?: string };

export function formatSeasonPayload(data?: {
  format?: SeasonFormat;
  startDate?: string;
}): SeasonPayload {
  if (!data)
    return;

  const payload: SeasonPayload = {
    format: data.format || "single-year",
  };
  if (payload.format === "cross-year" && data.startDate) {
    payload.startDate = new Date(data.startDate).toISOString();
  }

  return payload;
}

export function formatSeasonStringToDate(seasonDateString?: string): string | undefined {
  if (seasonDateString) {
    const date = new Date();
    const [day, month] = seasonDateString.split("-");
    date.setMonth(Number(month) - 1);
    date.setDate(Number(day));
    return formatDate(date, "YYYY-MM-DD");
  }

  return;
}

export function formatSeasonOption (season: number, type: SeasonFormat = "single-year"): { value: number; label: string } {
  // `type` defaults to `single-year`
  if (type === "cross-year") {
    const nextYear = (season + 1) - 2000;
    return { value: season, label: `${season}-${nextYear}` };
  }

  return { value: season, label: String(season) };
}

export function getCurrentCrossSeason (seasonStartDate: string, options: { startDate?: string | Date } = {}): number {
  // format of `seasonStartDate` is YYYY-MM-DD
  const [_startYear, startMonth, startDay] = seasonStartDate.split("-").map(Number);

  // Use the provided startDate or the current date
  const startDate = options.startDate ? new Date(options.startDate) : new Date();
  const currentDay = startDate.getDate();
  const currentMonth = startDate.getMonth() + 1;
  const currentYear = startDate.getFullYear();

  if (currentMonth > startMonth) {
      return currentYear;
  }

  if (currentMonth === startMonth) {
      return currentDay < startDay ? currentYear - 1 : currentYear;
  }

  return currentYear - 1;
}
