<script setup lang="ts">
import { RDrawer } from "#components";

const emit = defineEmits<{
  (event: "close"): void;
}>();

const { t } = useI18n();
const data = ref({ rating: 4, text: "" });
const segment = useSegment();
const toast = useToast();

function sendFeedback() {
  segment.track("User Left Feedback", { rating: data.value.rating, user_comment: data.value.text });
  toast.success("Your input is valuable to us and will help us improve our services.", "Thank you for your feedback!");
}
</script>

<template>
  <RDrawer
    ref="feedbackDrawerRef"
    is-opened
    has-close
    @close="emit('close')"
  >
    <template #content="{ close }">
      <div class="py-10 px-4 md:p-10">
        <div class="flex flex-col gap-6">
          <h5 class="text-2xl font-bold">
            {{ t("labels.send_feedback") }}
          </h5>

          <div class="flex flex-col gap-4">
            <p class="text-sm font-medium">
              {{ t("labels.send_feedback_description") }}
            </p>

            <RRating v-model="data.rating" />
          </div>

          <UTextarea v-model="data.text" placeholder="Tell us your feedback" :rows="5" autoresize />
        </div>

        <div class="flex items-center gap-4 mt-8">
          <UButton
            class="flex-1"
            size="lg"
            variant="secondary"
            :label="t('labels.cancel')"
            @click="close()"
          />
          <UButton
            class="flex-1"
            size="lg"
            :label="t('labels.send')"
            @click="sendFeedback(); close()"
          />
        </div>
      </div>
    </template>
  </RDrawer>
</template>
