<script setup lang="ts">
import type { IPlaylist } from "~/types";

const { t } = useI18n();
const props = defineProps<{
  playlist?: IPlaylist;
  showInstructions: boolean;
}>();
const toast = useToast();
const segment = useSegment();
const emit = defineEmits<{
  "playlist-updated": [name?: string];
  close: [playlistModified: boolean];
}>();
const isEdit = computed(() => Boolean(props.playlist?.name));
const initialData = ref({ name: "" });
const title = computed(() =>
  isEdit.value ? t("pages.playlists.modal.edit_title") : t("pages.playlists.modal.create_title"),
);

const { isLoading: isCreatingPlaylist, execute: onPlaylistCreate } = useAsyncState<IPlaylist | null>(
  () => createPlaylist(initialData.value.name),
  null,
  {
    immediate: false,
    onSuccess: (createdPlaylist: IPlaylist | null) => {
      if (createdPlaylist) {
        segment.track("Playlist Created", formatTrackPlaylist(createdPlaylist));
      }

      toast.success(t("pages.playlists.modal.add_playlist_success"));
      emit("playlist-updated");
      emit("close", true);
    },
    onError,
  },
);
const { isLoading: isUpdatingPlaylist, execute: onPlaylistUpdate } = useAsyncState(
  () => updatePlaylist(props.playlist!.id, initialData.value.name),
  null,
  {
    immediate: false,
    onSuccess: () => {
      toast.success(t("pages.playlists.modal.update_playlist_success"));
      emit("playlist-updated", initialData.value.name);
      emit("close", true);
    },
    onError,
  },
);

function onSubmit() {
  if (isEdit.value) {
    return onPlaylistUpdate();
  }
  return onPlaylistCreate();
}

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
}
onMounted(() => {
  if (props.playlist) {
    initialData.value = Object.assign(initialData.value, props.playlist);
  }
});
</script>

<template>
  <UModal
    appear
    :model-value="true"
  >
    <div class="p-4 pb-3 flex items-center justify-end">
      <UButton
        title="Close"
        variant="link-secondary"
        icon="close"
        class="h-5 w-5"
        @click="emit('close', false)"
      />
    </div>

    <div class="md:px-10 md:pb-10">
      <h2
        id="modalHeading"
        class="text-2xl font-bold text-black dark:text-white"
      >
        {{ title }}
      </h2>

      <div class="flex flex-col">
        <div
          v-if="showInstructions"
          class="text-xs font-medium dark:text-white mt-6"
        >
          {{ t("labels.add_playlist_instructions") }}
        </div>

        <UFormGroup class="mt-6" :label="t('labels.name')" name="name" required>
          <UInput v-model="initialData.name" placeholder="Enter playlist name" />
        </UFormGroup>


        <UButtonGroupConfirmCancel
            class="mt-6"
            cancel-variant="secondary"
            :cancel-label="t('labels.cancel')"
            :disabled="isCreatingPlaylist || isUpdatingPlaylist"
            :confirm-disabled="!initialData.name"
            :confirm-label="isEdit ? t('labels.save') : t('labels.create_and_save')"
            :on-cancel-click="() => emit('close', false)"
            :on-confirm-click="onSubmit"
        />
      </div>
    </div>
  </UModal>
</template>
