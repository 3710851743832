// import type { ITeam } from "~/types";

// export const TEAM_USER_ROLE: Record<string, ITeam["user_role"]> = {
// TODO: Improve typing here with interfaces

export const TEAM_USER_STATUS = {
  ACTIVE: "active",
  INVITED: "invited",
  PENDING: "pending"
} as const;

export const TEAM_USER_ROLE = {
  OWNER: "owner",
  ADMIN: "admin",
  COACH: "coach",
  MEMBER: "member",
  ATHLETE: "athlete",
  NOT_MEMBER: "not_member",
  PENDING_MEMBER: "pending_member"
} as const;
