import type { UIcon } from "#components";
import type { PxlIconName as PxlIconNameGenerated } from "#build/pxl-icons";
import { twMerge } from "tailwind-merge";

export type PxlIconName = PxlIconNameGenerated;
export type PxlIcon = PxlIconName | InstanceType<typeof UIcon>["$props"] | null | undefined;

export function iconBind(icon: PxlIcon, defaults?: Partial<InstanceType<typeof UIcon>["$props"]>): InstanceType<typeof UIcon>["$props"] {
  const baseIconClass = defaults?.class;

  if (typeof icon === "string") {
    return { ...defaults, class: baseIconClass, name: icon as PxlIconName };
  }

  if (typeof icon === "object" && icon !== null) {
    const iconClass = icon?.class;
    return { ...defaults, ...icon, class: twMerge("", baseIconClass, iconClass) };
  }

  // @ts-ignore
  return icon || {};
}
