<script setup lang="ts">
export interface EmptyImageProps {
  imageAttrs?: any;
}

const props = defineProps<EmptyImageProps>();
const colorMode = useColorMode();
const isDark = computed(() => colorMode.value === "dark");
</script>

<template>
  <svg
    v-if="isDark"
    v-bind="props.imageAttrs"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 3413">
      <rect
        id="Rectangle 720"
        width="200"
        height="200"
        rx="4"
        fill="#2C374B"
      />
      <g id="Icon/Image">
        <!-- eslint-disable-next-line max-len -->
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M130 64C132.652 64 135.196 65.0838 137.071 67.0124C138.946 68.9415 140 71.5577 140 74.2857V125.714C140 128.443 138.946 131.059 137.071 132.988C135.196 134.916 132.652 136 130 136H70.0034C67.3505 136.001 64.8061 134.918 62.9301 132.989C61.0541 131.06 60 128.443 60 125.714V74.2857C60 71.5571 61.0542 68.9405 62.9301 67.0114C64.8061 65.0823 67.3505 63.999 70.0034 64H130ZM114.991 97.4312L114.601 97.4647C114.084 97.551 113.605 97.8032 113.235 98.1854L94.6844 117.263L81.3843 108.145C80.9039 107.816 80.3282 107.669 79.754 107.727C79.1798 107.785 78.6427 108.046 78.2345 108.465L65.0044 120.572L65.0029 125.714C65.0029 127.078 65.5298 128.386 66.4678 129.351C67.4058 130.315 68.6778 130.858 70.0039 130.858H130C131.328 130.858 132.601 130.317 133.539 129.351C134.478 128.386 135.005 127.077 135.005 125.712V107.712L116.12 97.7022C115.651 97.4611 115.119 97.3778 114.601 97.4647L114.991 97.4312ZM82.5027 79.4299C80.5135 79.4299 78.6057 80.2425 77.1995 81.6894C75.7927 83.1364 75.0027 85.0986 75.0027 87.1442C75.0027 89.1903 75.7927 91.1525 77.1995 92.5995C78.6057 94.0464 80.5135 94.859 82.5027 94.859C84.492 94.859 86.3997 94.0464 87.8065 92.5995C89.2127 91.1525 90.0033 89.1903 90.0033 87.1442C90.0033 85.0986 89.2128 83.1364 87.8065 81.6894C86.3997 80.2425 84.492 79.4299 82.5027 79.4299Z"
          fill="#A9B7D1"
        />
      </g>
    </g>
  </svg>
  <svg
    v-else
    v-bind="props.imageAttrs"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="199"
      height="199"
      rx="3.5"
      fill="#AFB6C1"
      stroke="#DFDFDF"
    />
    <!-- eslint-disable-next-line max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130 64C132.652 64 135.196 65.0838 137.071 67.0124C138.946 68.9415 140 71.5577 140 74.2857V125.714C140 128.443 138.946 131.059 137.071 132.988C135.196 134.916 132.652 136 130 136H70.0034C67.3505 136.001 64.8061 134.918 62.9301 132.989C61.0541 131.06 60 128.443 60 125.714V74.2857C60 71.5571 61.0542 68.9405 62.9301 67.0114C64.8061 65.0823 67.3505 63.999 70.0034 64H130ZM114.991 97.4312L114.601 97.4647C114.084 97.551 113.605 97.8032 113.235 98.1854L94.6844 117.263L81.3843 108.145C80.9039 107.816 80.3282 107.669 79.754 107.727C79.1798 107.785 78.6427 108.046 78.2345 108.465L65.0044 120.572L65.0029 125.714C65.0029 127.078 65.5298 128.386 66.4678 129.351C67.4058 130.315 68.6778 130.858 70.0039 130.858H130C131.328 130.858 132.601 130.317 133.539 129.351C134.478 128.386 135.005 127.077 135.005 125.712V107.712L116.12 97.7022C115.651 97.4611 115.119 97.3778 114.601 97.4647L114.991 97.4312ZM82.5027 79.4299C80.5135 79.4299 78.6057 80.2425 77.1995 81.6894C75.7927 83.1364 75.0027 85.0986 75.0027 87.1442C75.0027 89.1903 75.7927 91.1525 77.1995 92.5995C78.6057 94.0464 80.5135 94.859 82.5027 94.859C84.492 94.859 86.3997 94.0464 87.8065 92.5995C89.2127 91.1525 90.0033 89.1903 90.0033 87.1442C90.0033 85.0986 89.2128 83.1364 87.8065 81.6894C86.3997 80.2425 84.492 79.4299 82.5027 79.4299Z"
      fill="white"
    />
  </svg>
</template>
