<script setup lang="ts">
import type { IBasicVideo, IEvent, IPlaylist } from "~/types";
import { EVENT_STATUS } from "~/constants";

const props = defineProps<{
  modelValue?: boolean;
  video?: IEvent | IBasicVideo | null;
  playlist?: null | IPlaylist;
  shareTime?: number;
}>();
const emit = defineEmits(["update:modelValue"]);
const isOpen = useVModel(props, "modelValue", emit);

const { t } = useI18n();
const toast = useToast();
const route = useRoute();
const segment = useSegment();
const closeModal = () => (isOpen.value = false);
const onError = (err: unknown) => toast.error(parseErrorMessage(err, { t }));
const { state: shareUrl, isLoading, execute: onShareDataChanged } = useAsyncState(onShare, "", { onError, immediate: false, resetOnExecute: true, shallow: false });
const isDisableShareTime = computed<boolean>(() => Boolean(props.playlist) || (props.video?.type === "event" && (props.video as IEvent).status === EVENT_STATUS.LIVE));

function getSharedVideoUrl(): string {
  const baseURL = `${window.location.origin}${useRuntimeConfig().app.baseURL}`;

  let sharedRoute = "";

  if (props.playlist) {
    sharedRoute = `user/content/?playlistId=${props.playlist.id}`;
  } else if (props.video && props.video.type === "event") {
    const editorRoute = `events/editor/${props.video.id}`;
    const eventRoute = `events/${props.video.id}`;
    const videoRouteBase = `events/${props.video.eventId}`;
    const videoRouteFull = `events/${props.video.eventId}?videoType=event&videoId=${props.video.id}`;
    sharedRoute = route.fullPath.includes(editorRoute)
      ? editorRoute
      : route.path.includes(videoRouteBase) && route.query.videoType === "event" && route.query.videoId === props.video.id
        ? videoRouteFull
        : eventRoute;
  } else {
    sharedRoute = `events/${props.video?.eventId}?videoType=${props.video?.type}&videoId=${props.video?.id}`;
  }

  return `${baseURL}${sharedRoute}`;
}

function onShare(): Promise<string | undefined> {
  if (!props.video && !props.playlist) return Promise.reject(new Error("Can't share event! Event not found!"));
  if (props.video?.id === "demo") return Promise.reject(new Error("Can't share demo event!"));

  const baseURL = getSharedVideoUrl();

  if (props.playlist) {
    return getPlaylistShareURL(props.playlist, { baseURL, accessToken: route.query.accessToken as string });
  }

  if (props.video) {
    return getVideoShareURL(props.video, { baseURL, accessToken: route.query.accessToken as string });
  }

  return Promise.resolve("");
}

function handleShare(provider: string) {
  if (props.playlist) {
    segment.track("Playlist Shared", { ...formatTrackPlaylist(props.playlist), ...{ share_type: provider } });
  } else if (props.video) {
    segment.track("Video Shared", { ...formatTrackVideo(props.video), ...{ share_type: provider } });
  }
}

const heading = computed(() => (props.playlist ? t("labels.share_this_playlist") : t("labels.share_this_match")));
const description = computed(() => props.playlist?.name || props.video?.title);

watch(() => [props.video, props.playlist], () => onShareDataChanged(), { immediate: true });
</script>

<template>
  <UModal v-model="isOpen">
    <div class="p-4">
      <RLoadingOverlay v-if="isLoading" />

      <div class="mb-1 text-end">
        <UButton title="Close" variant="link-secondary" icon="close" class="h-5 w-5" @click="closeModal()" />
      </div>

      <!-- TEXT -->
      <div class="text-center text-black dark:text-white space-y-2">
        <h1 class="text-sm font-bold" v-text="heading" />
        <p class="text-xs font-medium text-neutral-light-800" v-text="description" />
      </div>

      <div class="flex flex-col gap-6 md:p-6">
        <RShare
          :url="shareUrl || ''"
          :text="`Watch '${props.playlist?.name || props.video?.title}' at Pixellot!`"
          :time="isDisableShareTime ? undefined : props.shareTime"
          has-email-provider
          @share="handleShare"
        />
      </div>
    </div>
  </UModal>
</template>
