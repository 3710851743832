<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import type { NuxtLinkProps } from "#app";
import { UIcon, UIconLoading, ULink } from "#components";
import type { PxlIcon } from "~/components/U/Icon";
import { iconBind } from "~/components/U/Icon";

const button = cva(
  [
    "inline-flex justify-center items-center flex-shrink-0 rounded transition-colors cursor-pointer disabled:cursor-not-allowed focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:ring-2 focus-visible:ring-inset",
    "font-medium",
  ],
  {
    variants: {
      block: {
        true: "w-full flex",
      },
      text: {
        sm: "text-sm leading-6 gap-x-2",
        md: "text-sm leading-6 gap-x-2",
        lg: "text-sm leading-6 gap-x-2",
      },
      padding: {
        sm: "px-4 py-1",
        md: "px-4 py-2",
        lg: "px-4 py-3",
      },
      square: {
        sm: "p-2",
        md: "p-3",
        lg: "p-4",
      },
      variant: {
        primary:
          "bg-blue-500 hover:bg-blue-600 active:bg-blue-700 disabled:bg-blue-200 dark:disabled:bg-[#2b8cada1] dark:disabled:text-neutral-dark-300 focus-visible:outline-blue-500 dark:focus-visible:outline-blue-400 text-white",
        "primary-outline":
          "bg-transparent ring-1 ring-inset ring-current focus-visible:ring-2 text-blue-500 hover:text-blue-600 active:text-blue-700 disabled:text-blue-200 dark:disabled:text-[#2b8cada1] dark:disabled:border-[#2b8cada1] focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400",
        secondary:
          "bg-neutral-light-100 hover:bg-neutral-light-200 active:bg-neutral-light-300 disabled:bg-neutral-light-50 disabled:text-neutral-light-700 dark:disabled:text-neutral-dark-300 dark:bg-neutral-dark-500 dark:hover:bg-neutral-dark-600 dark:active:bg-neutral-light-300 dark:disabled:bg-neutral-dark-600 focus-visible:outline-neutral-light-200 dark:focus-visible:outline-neutral-light-300 text-black dark:text-white dark:active:text-black",
        "secondary-outline":
          "bg-transparent border border-neutral-light-700 hover:border-neutral-light-800 active:border-neutral-light-900 disabled:border-neutral-light-600 dark:border-neutral-dark-500 dark:hover:border-neutral-light-700 dark:active:border-neutral-light-700 dark:disabled:border-neutral-dark-300 focus-visible:outline-neutral-light-200 dark:focus-visible:outline-neutral-light-300 text-black dark:text-white dark:active:text-black",
        danger:
          "bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-200 focus-visible:outline-red-500 dark:focus-visible:outline-red-400 text-white",
        light:
          "bg-white text-black disabled:text-neutral-light-700 dark:bg-neutral-dark-700 dark:text-white hover:shadow-[0px_8px_32px_0px_#051b441f] active:shadow-[0px_8px_32px_0px_#051b441f] [&_.pxl-icon]:text-neutral-light-700 [&:disabled_.pxl-icon]:text-neutral-light-500 [&:disabled_.pxl-icon]:dark:text-neutral-dark-700",
        dark: "bg-black text-white bg-opacity-70 hover:bg-opacity-100 active:bg-opacity-100 disabled:bg-opacity-50 disabled:text-opacity-75",
        "link-primary":
          "bg-transparent p-0 text-blue-500 hover:text-blue-600 active:text-blue-700 disabled:text-blue-200",
        "link-secondary":
          "bg-transparent p-0 text-neutral-light-700 hover:text-neutral-light-800 active:text-neutral-light-900 disabled:text-neutral-light-600 dark:text-neutral-dark-200 dark:hover:text-neutral-light-700 dark:active:text-neutral-light-700 dark:disabled:text-neutral-dark-300",
        "link-danger": "bg-transparent p-0 text-red-500 hover:text-red-600 active:text-red-700 disabled:text-red-200",
        "text-link": "bg-transparent p-0 hover:text-blue-600 active:text-blue-700 disabled:text-blue-200",
        "text-link-secondary": "bg-transparent p-0 hover:opacity-70",
        clean: "bg-transparent",
      },
    },
    compoundVariants: [{ variant: ["primary", "danger"], text: ["lg"], class: "font-bold" }],
    defaultVariants: {
      variant: "primary",
    },
  },
);

type ButtonProps = VariantProps<typeof button>;

const props = withDefaults(
  defineProps<
    NuxtLinkProps & {
      as?: string | Component;
      class?: string;
      type?: HTMLButtonElement["type"];
      block?: boolean;
      loading?: boolean;
      disabled?: boolean;
      label?: string | null;
      icon?: PxlIcon;
      leadingIcon?: PxlIcon;
      trailingIcon?: PxlIcon;
      trailing?: boolean;
      leading?: boolean;
      square?: boolean;
      truncate?: boolean;
      size?: ButtonProps["text"];
      variant?: ButtonProps["variant"];
    }
  >(),
  {
    type: "button",
    size: "md",
    variant: "primary",
    label: null,
    icon: null,
    leadingIcon: null,
    trailingIcon: null,
    trailing: false,
    leading: false,
    square: false,
    truncate: false,
  },
);

const slots = useSlots();
const isLeading = computed(
  () =>
    (props.icon && props.leading) ||
    (props.icon && !props.trailing) ||
    (props.loading && !props.trailing) ||
    props.leadingIcon,
);
const isTrailing = computed(
  () => (props.icon && props.trailing) || (props.loading && props.trailing) || props.trailingIcon,
);
const isSquare = computed(() => props.square || (!slots.default && !props.label));
const buttonClass = computed(() => {
  return twMerge(
    button({
      variant: props.variant,
      text: props.size,
      square: isSquare.value ? props.size : null,
      padding: isSquare.value ? null : props.size,
      block: props.block,
    }),
    props.class,
  );
});

const iconBase = "h-4 w-4";
const leadingIconName = computed(() => props.leadingIcon || props.icon);
const trailingIconName = computed(() => props.trailingIcon || props.icon);
</script>

<template>
  <ULink
    :as="props.as"
    :type="props.type"
    :disabled="props.disabled || props.loading"
    :class="buttonClass"
    :to="props.to"
    :href="props.href"
    :target="props.target"
  >
    <slot
      name="leading"
      :disabled="props.disabled"
      :loading="props.loading"
    >
      <UIconLoading v-if="props.loading && isLeading" />
      <UIcon
        v-else-if="isLeading && leadingIconName"
        v-bind="iconBind(leadingIconName, { class: iconBase })"
        aria-hidden="true"
      />
    </slot>

    <slot>
      <span
        v-if="props.label"
        :class="[props.truncate ? 'text-left break-all line-clamp-1' : '']"
        :title="props.truncate ? props.label : undefined"
      >
        {{ props.label }}
      </span>
    </slot>

    <slot
      name="trailing"
      :disabled="props.disabled"
      :loading="props.loading"
    >
      <UIconLoading v-if="props.loading && !isLeading" />
      <UIcon
        v-else-if="isTrailing && trailingIconName"
        v-bind="iconBind(trailingIconName, { class: iconBase })"
        aria-hidden="true"
      />
    </slot>
  </ULink>
</template>
