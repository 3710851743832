import type { AvailableRouterMethod, NitroFetchOptions, NitroFetchRequest, TypedInternalResponse } from "nitropack";

export async function useAPI<T = unknown, R extends NitroFetchRequest = NitroFetchRequest>(
  request: R,
  opts?: NitroFetchOptions<R, AvailableRouterMethod<R>>,
): Promise<TypedInternalResponse<R, T>> {
  const baseURL = useRuntimeConfig().public.apiBaseURL;
  const token = useUserToken().value;

  function onAuthFailure() {
    logoutUser();
    return navigateToLogin({ errorCode: "session-expired" });
  }

  const customFetch = $fetch.create({
    baseURL,
    headers: {
      // not send Authorization: undefined for not logged in users (sharing)
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    onRequestError() {
      // Since we can't access the status code here and check for 401 - we're assuming that any request error is a session error,
      // until we will have a fix by DevOps team for ISTIO service: https://github.com/istio/istio/issues/29425
      onAuthFailure();
    },
    onResponseError({ response }) {
      if (response.status === 401) onAuthFailure();
    },
  });

  return customFetch(request, opts);
}
