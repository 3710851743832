<script setup lang="ts">
import type { ClassNameValue } from "tailwind-merge";
import type { PxlIconName } from "./";
import { twMerge } from "tailwind-merge";

const props = defineProps<{
  name: PxlIconName | "empty";
  disabled?: boolean;
  class?: ClassNameValue;
  tooltip?: string;
  onClick?: () => void;
}>();

const colorClass = computed(() => props.disabled ? "cursor-not-allowed text-neutral-light-500 dark:text-neutral-dark-400" : "text-neutral-light-700 dark:text-neutral-dark-200");
</script>

<template>
  <button
    v-if="props.onClick"
    v-tooltip="props.tooltip"
    type="button"
    class="p-0 inline-flex"
    @click.stop.prevent="!props.disabled && props.onClick()"
  >
    <i
      :class="
        twMerge(
          `i-pxl-icon`,
          name === 'empty' ? 'h-4 w-4' : `i-pxl-${name}`,
          props.class || colorClass,
        )
      "
    />
  </button>
  <i
    v-else
    v-tooltip="props.tooltip"
    :class="
      twMerge(
        `i-pxl-icon`,
        name === 'empty' ? 'h-4 w-4' : `i-pxl-${name}`,
        props.class || colorClass,
      )
    "
  />
</template>
